import {registerVizType} from '../../../model/viz/visualizationTypes';
import {TableViz} from './TableViz';
import _ from "lodash";
import {VeckoColors} from "../../../../style/VeckoColors";
import {Colors} from "@blueprintjs/core";
import {getVisualizationTheme, VizThemeDefinition} from "../../utils/visualizationTheme";
import {getTitle} from "../common";
import {VisualizationInstance} from "../../../model/viz/VisualizationInstance";
import {TFunction} from "i18next";

const defaultUiParams = {
    percentageMode: false,
    showTrend: true,
    showSecondary: true,
    valueUnit: null,
    mainFont: {
        size: 40,
        color: VeckoColors.DEFAULT_FG
    },
    trendFont: {
        size: 24,
        color: VeckoColors.DEFAULT_FG
    },
    secondaryFont: {
        size: 20,
        color: Colors.GRAY1
    }
};

const getDefaultUiParams = (themeDefinition: VizThemeDefinition) => {
    const visualizationTheme = getVisualizationTheme(themeDefinition);

    return _.merge({}, defaultUiParams, {
        mainFont: {color: visualizationTheme.isDark ? VeckoColors.WHITE : VeckoColors.DEFAULT_FG},
        trendFont: {color: visualizationTheme.isDark ? VeckoColors.WHITE : VeckoColors.DEFAULT_FG},
        secondaryFont: {color: visualizationTheme.isDark ? VeckoColors.WHITE : Colors.GRAY1},
    });
}
const enrichItems = (items, parent) => {
    const enrichedItems = [];
    items.forEach(item => {
        const itemClone = {...item};
        itemClone.parent = parent;
        if (item.children?.length > 0) {
            itemClone.children = enrichItems(item.children, item);
        }
        enrichedItems.push(itemClone);
    })
    return enrichedItems;
}

registerVizType({
    name: 'TABLE',
    component: TableViz,
    defaultUiParams: (vizDef) => getDefaultUiParams(_.get(vizDef.spec, ['uiParams', 'theme'])),
    title: (vizInstance: VisualizationInstance, t: TFunction) => {
        return getTitle(vizInstance, t) + (vizInstance.hasData() ? " " + t('feedbacks.conversations', {count: vizInstance.data.totalCount}) : null);
    },
    noDataPolicy: {
        hasData: vizInstance => {
            return !_.isNil(vizInstance.data.items) && vizInstance.data.items.length > 0;
        }
    },
    prepareData: (data) => {
        return {items: enrichItems(data.items, null), totalCount: data.totalCount};
    }
});