import { FieldValue } from "./field/FieldValue";
import { DistributionItem } from "./distribution/DistributionItem";
import { DistributionKind } from "./distribution/DistributionKind";
import { SortOrder } from "../../utils/query/SortOrder";
import { TFunction } from "i18next";
import _ from "lodash";
import { findFirstTreeNode, flattenTreeNodes } from "../../utils/treeUtils";

export class TopicStat {
    readonly topic: FieldValue;
    readonly parent?: TopicStat;
    children: Array<TopicStat> = [];
    value: TopicStatValue
    distribution: { [distributionItemId: string]: DistributionValue };
    data: any[] = [];

    static create(topic: FieldValue, value: TopicStatValue, parent?: TopicStat): TopicStat {
        const topicStat = new TopicStat(topic,  parent, value);
        if (!_.isNil(parent)) {
            parent.children.push(topicStat);
        }
        return topicStat;
    }

    private constructor(topic: FieldValue, parent:TopicStat, value: TopicStatValue, distribution: { [p: string]: DistributionValue } = {}) {
        this.topic = topic;
        this.parent = parent;
        this.value = value;
        this.distribution = distribution;
        this.data.push({name: "volume", value: value});
        this.data.push({name: "distribution", value: distribution});
    }

    get level(): number {
        return this.topic.level;
    }

    get id(): string {
        return this.topic.getId();
    }

    isLeaf(): boolean {
        return !this.hasChildren();
    }

    hasChildren(): boolean {
        return this.children.length > 0;
    }

    putEmptyDistribution(distributionKind: DistributionKind):void {
        this.distribution = Object.fromEntries(
            distributionKind.items
                .map(distributionItem => [
                        distributionItem.id,
                        new DistributionValue(distributionItem, 0, 0)
                    ]
                )
        )
    }

    getDistribution(distributionItem: DistributionItem):DistributionValue {
        return this.distribution[distributionItem.id];
    }

    putDistribution(distributionItem: DistributionItem, count: number, percent: number):void {
        this.distribution[distributionItem.id] = new DistributionValue(distributionItem, count, percent);
    }

    getTopicLabel(t: TFunction) {
        return this.topic.getLabel(t);
    }

    getColumnSortValue(t: TFunction, columnName: string) {
        if (columnName === 'topic') {
            return this.getTopicLabel(t);
        }
        if (columnName === 'volume') {
            return this.value.count;
        }
        return this.distribution[columnName].percent;
    }
}

export class TopicStatValue {
    count: number;
    percent: number;

    constructor(count: number, percent: number) {
        this.count = count;
        this.percent = percent;
    }
}

export class DistributionValue {
    readonly distributionItem: DistributionItem;
    count: number;
    percent: number;

    constructor(distributionItem: DistributionItem, count: number, percent: number) {
        this.distributionItem = distributionItem;
        this.count = count;
        this.percent = percent;
    }
}

export class TopicStatUtils {
    static sort(topicsStats: Array<TopicStat>, orderedColumnName: string, direction: SortOrder, t: TFunction): Array<TopicStat> {
        const label = (ts:TopicStat) => ts.getColumnSortValue(t, 'topic');
        const get = (ts:TopicStat) => ts.getColumnSortValue(t, orderedColumnName);

        const lodashDirection: any = direction.name.toLowerCase();
        const orderedTopicsStats = _.orderBy(topicsStats, [get, label], [lodashDirection, lodashDirection]);

        // sort children
        orderedTopicsStats.forEach(ts => {
            ts.children = TopicStatUtils.sort(ts.children, orderedColumnName, direction, t);
        });

        return orderedTopicsStats;
    }

    static flatten(topicsStats: Array<TopicStat>): Array<TopicStat> {
        return flattenTreeNodes(topicsStats, (ts:TopicStat) => ts.children);
    }

    static findByTopic(topicsStats: Array<TopicStat>, topic: FieldValue): Maybe<TopicStat> {
        return findFirstTreeNode(topicsStats, (ts:TopicStat) => ts.children, (ts:TopicStat) => ts.topic === topic);
    }

    static findById(topicsStats: Array<TopicStat>, id: string): Maybe<TopicStat> {
        return findFirstTreeNode(topicsStats, (ts:TopicStat) => ts.children, (ts:TopicStat) => ts.id === id);
    }
}