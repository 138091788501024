import {Param} from "../params/Param";
import {VisualizationDefinition} from "../viz/VisualizationDefinition";
import _ from "lodash";
import {TFunction} from "i18next";
import {index} from "../../../utils/collectionUtils";

export class DashboardDefinition {
    readonly id: string;
    readonly isDashboard: boolean = false;
    readonly commonFilters: any;
    readonly parameters: Array<Param>;
    readonly parametersMap: Dict<Param>;
    readonly visualizations: Array<VisualizationDefinition>;
    readonly visualizationsMap: Dict<VisualizationDefinition>;

    constructor(id, isDashboard, commonFilters, parameters, visualizations) {
        this.id = id;
        this.isDashboard = isDashboard;
        this.commonFilters = commonFilters;
        this.parameters = parameters || [];
        this.parametersMap = index(this.parameters, p => p.name);
        this.visualizations = visualizations || [];

        if (_.isEmpty(this.id)) {
            throw new Error(`Invalid dashboard definition. id cannot be null or empty`);
        }

        this.visualizationsMap = index(this.visualizations, v => v.id);
    }

    getLabel(t: TFunction): string {
        return t(`report.${this.id}`);
    }

    getVizDefinition(id: string): VisualizationDefinition {
        return this.visualizationsMap[id];
    }
}