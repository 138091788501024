import i18next, {TFunction} from 'i18next';
import en from '../../i18n/en';
import fr from '../../i18n/fr';
import {services} from './services';
import _ from "lodash";
import {initI18n} from "../../i18n/i18n";
import {Settings} from "luxon";
import {visualizationTypes} from "../../report/model/viz/visualizationTypes";

type TranslateFallback = string | (() => string);
export type I18nLabel = {
    key: string,
    value: string,
    lang: string
}

export class I18nService {
    translateFn: TFunction;

    async init(): Promise<void> {
        this.translateFn = await initI18n({en, fr}, {react: null}, this.onLocaleChange);
    }

    onLocaleChange(locale: string) {
        Settings.defaultLocale = locale;
    }

    addTranslationsFromConfiguration(fieldsConfiguration: Vecko.FieldsConfiguration,
                                     uiConfiguration: Vecko.UIConfiguration): void {
        const translations = this.getTranslationsFromConfigurations(fieldsConfiguration, uiConfiguration);

        Object.entries(translations).forEach(([lng, resources]) => {
            i18next.addResourceBundle(lng, 'translations', resources, true, true);
        });
        //for debug purpose display all keys and values
     /*   const resourcesArray: string[] = Object.keys(i18next.options.resources);
        console.log(i18next.options.resources);*/
    }

    getCurrentLanguage(): string {
        const language = i18next.language;
        if (language.includes('-')) {
            return language.split('-')[0];
        }
        return language;
    }

    public translate(i18nKey: string, args?: object): Maybe<string> {
    return (this.translateFn as any)(i18nKey, args);
    }

    public translateSilently(i18nKey: string, fallback: TranslateFallback, args?: object): string {
        return this.translateWithFallbackKeys([i18nKey], fallback, args);
    }

    public translateWithFallbackKeys(keys: Array<string>, fallback: TranslateFallback, args?: object) {
        for (let key of keys) {
            if (i18next.exists(key)) {
                return this.translate(key, args);
            }
        }
        return _.isFunction(fallback) ? (fallback as Function)() : fallback;
    }

    private getTranslationsFromConfigurations(fieldsConfiguration: Vecko.FieldsConfiguration,
                                              uiConfiguration: Vecko.UIConfiguration): Dict<any> {
        const result = {};

        // fields translations
        Object.entries(fieldsConfiguration).forEach(([key, fieldConfiguration]) => {
            if (fieldConfiguration.labels) {
                Object.entries(fieldConfiguration.labels).forEach(([lang, label]) => I18nService.addTranslation(lang, key, label, result));
            }

            if (fieldConfiguration.values) {
                I18nService.addTranslations(key, fieldConfiguration.values, result);
            }
        });

        (uiConfiguration.reports || []).forEach(report => {
            if (report.labels) {
                Object.entries(report.labels).forEach(
                    ([lang, label]) => I18nService.addTranslation(lang, `report.${report.id}`, label, result));
            }
            // params labels
            if (report.parameters) {
                report.parameters.forEach(param => {
                    if (param.labels) {
                        Object.entries(param.labels).forEach(
                            ([lang, label]) => I18nService.addTranslation(lang, `vizParam.${report.id}.${param.name}`, label, result));
                    }
                });
            }
            // visualization labels
            if (report.visualizations) {
                report.visualizations.forEach(viz => {
                    if (viz.labels) {
                        Object.entries(viz.labels).forEach(
                            ([lang, label]) => I18nService.addTranslation(lang, `viz.${report.id}.${viz.id}`, label, result));
                    }

                    if (viz.uiParams && viz.uiParams.title && viz.uiParams.title.labels) {
                        Object.entries(viz.uiParams.title.labels).forEach(
                            ([lang, label]) => I18nService.addTranslation(lang, `viz.${report.id}.${viz.id}.title`, label, result));
                    }
                    const vizType = visualizationTypes.get(viz['@type']);

                    const vizComponent = vizType.component;
                    if (vizComponent.hasOwnProperty("getLabels")) {
                        vizComponent.getLabels(report, viz).forEach(label => I18nService.addTranslation(label.lang, label.key, label.value, result))
                    }
                });
            }

            // parameters labels
            if (report.parameters) {
                report.parameters.forEach(param => {
                    if (param.title?.labels) {
                        Object.entries(param.title.labels).forEach(
                            ([lang, label]) => I18nService.addTranslation(lang, `report.${report.id}.param.${param.name}.title`, label, result));
                    }
                });
            }
        });

        _.forEach(uiConfiguration.translations, (values, lang) => {
            _.forEach(values, (value, key) => {
                I18nService.addTranslation(lang, key, value, result);
            });
        });

        return result;
    }


    private static addTranslation(lang, key, value, result) {
        if (!result[lang]) {
            result[lang] = {};
        }
        result[lang][key] = value;
    }

    private static addTranslations(key, values, result) {
        values.forEach(v => {
            const path = `${key}/${v.name}`;
            if (v.labels) {
                Object.entries(v.labels).forEach(([lang, value]) => I18nService.addTranslation(lang, path, value, result));
            }

            if (v.children) {
                I18nService.addTranslations(path, v.children, result);
            }
        });
    }
}

services.registerService('i18nService', new I18nService());
