import React from "react";
import {CountWithUnit} from "../../explore/topicsStat/component/CountWithUnit";
import _ from "lodash";

interface CellProps {
    count: number,
    percent?: number,
    color?: string,
    nbDecimal?: number,
    nbDecimalPercent?: number,
    textAlign?: 'left' | 'right' | 'center',
}

export class Cell extends React.PureComponent<CellProps> {
    render() {
        let {count, percent, color, nbDecimal, nbDecimalPercent, textAlign} = this.props;
        const numStyle = {fontWeight: 800};
        const unitStyle = {fontWeight: 400};
        const countToDisplay = _.isNil(nbDecimal) ? count : _.round(count, nbDecimal)
        const align = _.isNil(textAlign) ? 'left' : textAlign;

        return <div style={{minWidth: 110, maxWidth: 110, color: color, display: 'inline-block', textAlign: align}}>
            {percent ?
                <div style={{minWidth: '50%', display: 'inline-block', textAlign: 'left'}}>
                    <CountWithUnit i18nKey='misc.percent'
                                   count={_.round(percent, _.isNil(nbDecimalPercent) ? 2 : nbDecimalPercent)}
                                   countSpanStyle={numStyle}
                                   unitSpanStyle={unitStyle}/>
                </div> : null
            }
            <span>{percent ? "(" + countToDisplay + ")" : countToDisplay}</span>
        </div>
    }
}