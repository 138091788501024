import {Button, Checkbox, Colors} from '@blueprintjs/core';
import _ from 'lodash';
import React from 'react';
import './table.scss';
import {equalsInAnyOrder, equalsInSameOrder} from '../../utils/collectionUtils';
import {InfiniteCache} from "../../utils/InfiniteCache";
import classnames from 'classnames';
import {ValueRenderer, ValueRendererProps, ValueRendererPropsWithTranslation} from "./TableRenderers";
import { WithTranslation, WithTranslationProps} from "react-i18next";
import {SortOrder} from "../../utils/query/SortOrder";
import {SmartIcon} from "../icon/SmartIcon";
import {flattenTreeNodes} from "../../utils/treeUtils";
import {$Subtract} from "react-i18next/helpers";

interface ValueRendererType<A, T extends ValueRenderer<A, ValueRendererProps<A> & WithTranslationProps>> {
    new(props: T['props']): T;
}

export type TableCellRenderer<T> = (column: TableDatasetDisplay<T>, rowValue: T, rowIndex: number, colIndex: number) => JSX.Element | ValueRenderer<T, ValueRendererProps<T>>;
export type TableHeaderRenderer<T> = (column: TableDatasetDisplay<T>, colIndex: number, direction: SortOrder, sortHandler: (columnName: string, columnIndex: number, sortDirection: SortOrder, event?) => void) => JSX.Element;

export type TableRendererOrJSXElement<T, R = (TableCellRenderer<T> | TableHeaderRenderer<T>)> = R | JSX.Element |
    ValueRendererType<T, ValueRenderer<T, ValueRendererProps<T> & WithTranslationProps>> |
    React.ComponentType<Omit<$Subtract<ValueRendererPropsWithTranslation<T>, WithTranslationProps>, keyof WithTranslation<any>> & WithTranslationProps>;

export interface TableDatasetDisplay<T> {
    columnName: string,
    headerRenderer?: TableRendererOrJSXElement<T, TableHeaderRenderer<T>>;
    dataRenderer: TableRendererOrJSXElement<T, TableCellRenderer<T>>;
    style?: React.CSSProperties
}

export interface TableDataset<T> {
    data: Array<T>;
    display: Array<TableDatasetDisplay<T>>
}

interface TableIndexProps<T> {
    enabled: boolean;
    columnLabel?: string;
    renderer?: TableCellRenderer<T>;
}

interface TableHighlightProps<T> {
    isHighlighted?: (rowValue: T, rowIndex: number) => boolean;
    highlightedClassName: string;
}

export const SelectMode = {
    CHECKBOX: 'CHECKBOX',
    ROW: 'ROW'
}
type SelectModeType = typeof SelectMode[keyof typeof SelectMode];

interface TableProps<T> {
    style?: React.CSSProperties;
    color?: string;
    separatorColor?: string;
    dataset: TableDataset<T>;
    index?: TableIndexProps<T>;
    expandable: boolean;
    highlight?: TableHighlightProps<T>;
    selectable?: boolean;
    selection?: Array<T>;
    selectionChanged?: (newSelection: Array<T>, item: T, event) => void;
    selectMode?: SelectModeType,
    cellCanSelect?: boolean | Array<number>;
    selectedClassName?: string,
    showHeader?: boolean;
    itemEqual?: EqualComparator<T>;
    headerClassName?: string;
    rowClassName?: string;
    itemId: (T) => string;
    onCellDoubleClick: (t: TableUIData<T>) => void;

    onSort: (columnName: string, sortDirection: SortOrder, columnIndex?: number, event?) => void;

    onExpandClick: (value: T, event: any) => void;

    onCollapseClick: (value: T, event: any) => void;

    expandedValues: Array<T>;

    isExpandedFunction: (expandedValues: Array<T>, rowValue: T) => boolean;
}

export interface TableUIData<T> {
    raw: T,
    selected: boolean,
    expanded: boolean,
    hasChildren: boolean,
    level: number,
    children: Array<TableUIData<T>>
}

export class Table<T> extends React.Component<TableProps<T>> {
    static defaultProps: Partial<TableProps<any>> = {
        index: {
            enabled: false
        },
        color: Colors.GRAY1,
        selectable: false,
        selection: [],
        cellCanSelect: false,
        selectMode: SelectMode.CHECKBOX,
        showHeader: true,
    };

    state = {
        orderColumnIndex: null,
        orderDirection: null,
        expandedValues: [] as Array<T>
    };

    selectionCheckboxHandler = new InfiniteCache<TableUIData<T>, (event) => void>(value => (event) => this.changeSelection(value, !this.isSelected(value), event))
    cellClickHandler = new InfiniteCache<TableUIData<T>, (event) => void>(value => (event) => this.changeSelection(value, !this.isSelected(value), event))


    expandHandlerCache = new InfiniteCache<T>(value => (event) => this.props.onExpandClick(value, event));
    collapseHandlerCache = new InfiniteCache<T>(value => (event) => this.props.onCollapseClick(value, event));

    cellDoubleClickHandler = new InfiniteCache<TableUIData<T>, () => void>(value => () => {
        if (this.props.onCellDoubleClick) {
            this.props.onCellDoubleClick(value);
        }
    })

    directionGetter = (columnIndex: number): SortOrder => {
        if (this.state.orderColumnIndex !== columnIndex) {
            return null;
        }
        return this.state.orderDirection;
    }

    changeSelection(value: TableUIData<T>, selected, event) {
        value.selected = selected;

        const newSelection = selected ? [...this.props.selection, value.raw] : this.props.selection.filter(s => s !== value.raw);
        this.props.selectionChanged(newSelection, value.raw, event);
    }

    onSort(columnName: string, columnIndex: number, sortDirection: SortOrder, event) {
        this.setState({orderDirection: sortDirection, orderColumnIndex: columnIndex})
        if (this.props.onSort) {
            this.props.onSort(columnName, sortDirection, columnIndex, event)
        }
    }

    isSelected(value: TableUIData<T>): boolean {
        return value.selected;
    }

    getUiData(data: Array<T>, selection: Array<T>, level: number): Array<TableUIData<T>> {
        const nextLevel = level + 1;
        const nodes = (data || [])
            .map(d => {
                const hasChildren = (d as any).hasChildren ? (d as any).hasChildren() : ((d as any).children && Array.isArray((d as any).children) ? (d as any).children.length > 0 : false);
                const expanded = this.isExpanded(d);
                const children = hasChildren && expanded ? this.getUiData((d as any).children, selection, nextLevel) : null;
                return {
                    level: level,
                    raw: d,
                    expanded: expanded,
                    selected: selection.includes(d),
                    hasChildren: hasChildren,
                    children: children
                }
            });

        const flatterned = flattenTreeNodes(nodes, (node) => node.children);
        return flatterned;

    }

    isExpanded(rowValue: T): boolean {
        const expandedValues = this.props.expandedValues ? this.props.expandedValues : this.state.expandedValues;
        return this.props.isExpandedFunction ? this.props.isExpandedFunction(expandedValues, rowValue) : expandedValues.includes(rowValue)
    }

    isHighlighted(tableUIData: TableUIData<T>, rowIndex: number) {
        return this.props.highlight?.isHighlighted ? this.props.highlight.isHighlighted(tableUIData.raw, rowIndex) : false;
    }

    shouldComponentUpdate(nextProps: Readonly<TableProps<T>>, nextState: Readonly<any>, nextContext: any): boolean {
        let needRefresh;

        if (this.state.orderColumnIndex != nextState.orderColumnIndex
            || this.state.orderDirection != nextState.orderDirection
            || this.state.expandedValues != nextState.expandedValues) return true

        if (this.props.itemEqual) {
            needRefresh = !equalsInAnyOrder(nextProps.selection, this.props.selection, this.props.itemEqual);
        } else {
            needRefresh = !_.isEqual(nextProps.selection, this.props.selection);
        }

        if (needRefresh) {
            return needRefresh;
        }

        if (this.props.itemEqual) {
            needRefresh = !equalsInSameOrder(nextProps.dataset.data, this.props.dataset.data, this.props.itemEqual);
        } else {
            needRefresh = !_.isEqual(nextProps.dataset.data, this.props.dataset.data);
        }

        return needRefresh || !_.isEqual(_.omit(nextProps.dataset.display, _.functions(nextProps.dataset.display)), _.omit(this.props.dataset.display, _.functions(this.props.dataset.display)));
    }

    isCellCanSelect(index: number): boolean {
        if (!this.props.selectable) {
            return false;
        }

        if (this.isRowSelectable()) {
            return true;
        }

        if (typeof this.props.cellCanSelect === 'boolean') {
            return this.props.cellCanSelect;
        }

        if (_.isArray(this.props.cellCanSelect)) {
            return (this.props.cellCanSelect as Array<number>).indexOf(index) !== -1;
        }

        return false;
    }

    renderTableHeader(colIndex: number, className: string,
                      column: TableDatasetDisplay<T>): JSX.Element {
        const elementKey = `header-${colIndex}`;
        const style = this.getStyle(column);
        return <div key={elementKey} className={className} style={{...style, backgroundColor: this.props.color}}>
            {this.renderColumnHeader(column, colIndex)}
        </div>;
    }

    private getStyle(column: TableDatasetDisplay<T>) {
        const style = column.style ? {...column.style} : {flex: 1};
        if (!style.flex && !style.width) {
            style.flex = 1;
        }
        return style;
    }

    renderTableCell(key: string, colIndex: number, rowIndex: number, rowId: string, className: string, tableUIData: TableUIData<T>,
                    column: TableDatasetDisplay<T>): JSX.Element {
        const st = this.getStyle(column);

        let onClick;
        if (this.isCellCanSelect(colIndex)) {
            st.cursor = 'pointer';
            onClick = this.cellClickHandler.get(tableUIData);
        }

        const elementKey = `${key}-${colIndex}-${rowId}`;
        return <div key={elementKey} className={className} style={st} onClick={onClick}
                    onDoubleClick={this.cellDoubleClickHandler.get(tableUIData).bind(this)}>
            {this.renderCell(column, colIndex, rowIndex, tableUIData.raw)}
        </div>;
    }

    getData() {
        return this.getUiData(this.props.dataset.data, this.props.selection, 0);
    }

    isCheckboxSelectable() {
        return this.props.selectable && this.props.selectMode === SelectMode.CHECKBOX;
    }

    isRowSelectable() {
        return this.props.selectable && this.props.selectMode === SelectMode.ROW;
    }

    render() {
        const separatorColor = this.props.separatorColor || this.props.color;
        const indexProp = _.defaultsDeep({}, this.props.index, {enabled: false, columnLabel: '#'});


        const renderSelectorCell = (key, colIndex, rowIndex, className, rowValue) => {
            const id = this.generateUniqueId();
            const elementKey = `${key}-${colIndex}-${rowIndex}`;

            return <div key={elementKey} className={className}>
                <Checkbox id={id} name={id} label=''
                          checked={this.isSelected(rowValue)}
                          onChange={this.selectionCheckboxHandler.get(rowValue)}/>
            </div>;
        };
        const data = this.getData();
        const maxLevel = this.findMaxLevel(data);
        const hasOneChildren = data.filter(value => value.hasChildren).length > 0;

        const tableHeader = this.props.dataset.display.map((display, index) => {
            let className = 'vuiTableCell';
            if (index === 0) {
                className += ' vuiTableCell--first';
            }
            return this.renderTableHeader(index, className, display);
        });
        if (hasOneChildren) {
            const collapseColumn = {
                columnName: "collapseColumn",
                headerRenderer: null,
                dataRenderer: null,
                style: {width: 24}
            }
            tableHeader.splice(0, 0, this.renderTableHeader(-3, 'vuiTableCell vuiTableCell--collapse', collapseColumn));
        }
        if (indexProp.enabled) {
            const indexColumn = {
                columnName: "indexCol",
                headerRenderer: indexProp.columnLabel,
                dataRenderer: null,
                style: null
            }
            tableHeader.splice(0, 0, this.renderTableHeader(-1, 'vuiTableCell vuiTableCell--index', indexColumn));
        }
        if (this.isCheckboxSelectable()) {
            const selectColumn = {
                columnName: "selectColumn",
                headerRenderer: null,
                dataRenderer: null,
                style: null
            }
            tableHeader.splice(0, 0, this.renderTableHeader(-2, 'vuiTableCell vuiTableCell--select', selectColumn));
        }


        const rows = data.map((tableUIData, rowIndex) => {
            const rowClassName = ['vuiTableRow', this.props.rowClassName];
            if (this.isHighlighted(tableUIData, rowIndex)) {
                rowClassName.push(this.props.highlight.highlightedClassName);
            }
            if (this.isRowSelectable() && this.isSelected(tableUIData)) {
                rowClassName.push(this.props.selectedClassName);
            }

            const rowId: string = this.props.itemId?.(tableUIData.raw) ?? rowIndex + "";

            return (
                <div key={rowId} className={classnames(rowClassName)} style={{borderColor: separatorColor}}>
                    {this.isCheckboxSelectable()
                        ? renderSelectorCell(
                            'selector', -2, rowIndex,
                            'vuiTableCell vuiTableCell--select',
                            tableUIData
                        )
                        : ''}
                    {indexProp.enabled
                        ? this.renderTableCell(
                            'idx', -1, rowIndex,
                            rowId,
                            'vuiTableCell vuiTableCell--index',
                            tableUIData,
                            {columnName: 'idx', dataRenderer: indexProp.renderer ? indexProp.renderer : rowIndex + 1}
                        )
                        : ''}
                    {tableUIData.level > 0 ?
                        <div style={{width: 24 * tableUIData.level}}></div>
                        : null
                    }
                    {this.props.expandable && tableUIData.hasChildren ?
                        <Button
                            small={true}
                            minimal={true}
                            icon={<SmartIcon icon={tableUIData.expanded ? 'chevron-down' : 'chevron-right'}/>}
                            onClick={event => {
                                if (!this.props.expandedValues) {
                                    if (tableUIData.expanded) {
                                        tableUIData.expanded = false;
                                        this.state.expandedValues.splice(this.state.expandedValues.indexOf(tableUIData.raw), 1)
                                    } else {
                                        tableUIData.expanded = true;
                                        this.state.expandedValues.push(tableUIData.raw)
                                    }
                                    this.setState({expandedValues: [...this.state.expandedValues]})
                                } else {
                                    if (tableUIData.expanded) {
                                        this.props.onCollapseClick(tableUIData.raw, event);
                                    } else {
                                        this.props.onExpandClick(tableUIData.raw, event);
                                    }
                                }
                            }
                            }
                        /> : null
                    }

                    {this.props.dataset.display.map((display, colIndex) => {
                        let className = 'vuiTableCell';
                        if (colIndex === 0) {
                            className += ' vuiTableCell--first';
                        }

                        return this.renderTableCell('data', colIndex, rowIndex, rowId, className, tableUIData, display);
                    })}
                </div>
            );
        });

        return <div className="vuiTable" style={this.props.style}>
            {
                this.props.showHeader ?
                    <div className={classnames("vuiTableHeader", this.props.headerClassName)}>
                        {tableHeader}
                    </div> :
                    null
            }

            <div className="vuiTableRows">
                {rows}
            </div>
        </div>;
    }

    private renderCell(display: TableDatasetDisplay<T>, colIndex: number, rowIndex: number, rowValue: T): JSX.Element {

        const renderer = display.dataRenderer;
        const isWrappedValueRender = (renderer as any).WrappedComponent && (renderer as any).WrappedComponent.prototype.isComponent;
        const isValueRender = (renderer as any).prototype.isComponent;
        if (isWrappedValueRender || isValueRender) {
            const CellRender = (renderer as any);
            const cellRenderProps = {...this.props, display, colIndex, rowIndex, rowValue};
            return <CellRender {...cellRenderProps}/>
        }

        if (_.isFunction(renderer)) {
            return (renderer as any)(rowValue, display, rowIndex, colIndex);
        }

        return renderer as JSX.Element;
    }

    private renderColumnHeader(column: TableDatasetDisplay<T>, colIndex: number): JSX.Element {
        const renderer = column.headerRenderer;
        if (_.isNil(renderer)) {
            return null;
        }

        if (_.isFunction(renderer)) {
            return (renderer as TableHeaderRenderer<T>)(column, colIndex, this.directionGetter(colIndex), this.onSort.bind(this));
        }

        return renderer as JSX.Element;
    }

    private generateUniqueId() {
        return Math.random().toString(36).slice(2);
    }

    private findMaxLevel(data: Array<TableUIData<T>>): number {
        let currentMaxLevel = 0;
        data.forEach(value => {
                let currentLeafMaxLevel = value.children && value.children.length > 0 ? this.findMaxLevel(value.children) : value.level;
                if (currentLeafMaxLevel > currentMaxLevel) currentMaxLevel = currentLeafMaxLevel
            }
        )
        return currentMaxLevel;
    }
}
