import {Classes, Icon, Intent, Tooltip} from '@blueprintjs/core';
import React, {CSSProperties, ReactElement} from 'react';
import classNames from 'classnames';
import Color from "color";

interface StackedBarProperties {
    animate?: boolean,
    stripes?: boolean,
    style?: CSSProperties,
    className?: string,
    intent?: Intent,
    values: { style?: CSSProperties, color: Color, value: number }[]
}

/**
 * This is Blueprint ProgressBar with 2 additional props : style and meterStyle for setting custom style to
 * the progress bar track and the progress bar meter
 */
export class StackedBar extends React.PureComponent<StackedBarProperties> {
    renderTooltipContent(): ReactElement {
        return <>{
            this.props.values.map(value => <div style={{display: "inline"}}>
                <div style={{height: "10px", width: "10px", backgroundColor: value.color}}></div>
                <span>{value?.value | 0}</span></div>)
        }</>;
    }

    render() {
        const {animate = true, className, intent, stripes = true, values, style} = this.props;
        const classes = classNames(
            Classes.PROGRESS_BAR,
            Classes.intentClass(intent),
            {[Classes.PROGRESS_NO_ANIMATION]: !animate, [Classes.PROGRESS_NO_STRIPES]: !stripes},
            className,
        );
        // don't set width if value is null (rely on default CSS value)

        const total = values.map(value => value == null || value.value == null ? 0 : value.value)
            .reduce((previousValue, currentValue) => currentValue + previousValue, 0);
        const display = "flex";

        return (
            <>
                <div className={classes} style={{...style, display}}>
                    {
                        values.map(value => {
                            const width = value == null || value.value == null || value.value === 0 ? null : 100 * value.value / total + "%";
                            //const flex = '1';
                            const background = value.color;
                            return !width ? null :
                                <div style={{...value.style, width, background}}/>

                        })
                    }

                </div>
                <Tooltip content={this.renderTooltipContent()}>
                    <Icon icon='info-sign'/>
                </Tooltip></>
        )
            ;
    }

    clamp(val, min, max) {
        if (val == null) {
            return val;
        }
        if (max < min) {
            throw new Error('min cannot be greater than max');
        }
        return Math.min(Math.max(val, min), max);
    }
}