import _ from 'lodash';
import {NoData} from '../../view/components/noData';

export function registerVizType(conf: any) {
    visualizationTypes.add(new VisualizationType(conf));
}

export class VisualizationType {

    private _name: string;
    private _title: any;
    private _component: any;
    private _noDataPolicy: any;
    private _editor: any;
    private _defaultUiParams: any;
    private _defaultParams: (vizDef) => { size: number };
    private _specResolver: any;
    private _prepareViz: any;
  private _prepareData: (data) => any;
    private _editable: boolean;

    constructor(conf: any) {
        this._name = conf.name;
        this._title = conf.title;
        this._component = conf.component;
        this._noDataPolicy = conf.noDataPolicy;
        this._editor = conf.editor;
        this._defaultUiParams = conf.defaultUiParams;
        this._defaultParams = conf.defaultParams;
        this._specResolver = conf.specResolver;
        this._prepareViz = conf.prepareViz;
    this._prepareData = conf.prepareData;
        this._editable = conf.editable;
    }

    hasEditor() {
        return !_.isUndefined(this._editor);
    }

    getNoDataComponent() {
        if (_.isUndefined(this._noDataPolicy)) {
            return NoData;
        }
        if (_.isUndefined(this._noDataPolicy.component)) {
            return NoData;
        }
        return this._noDataPolicy.component;
    }

    get title(): any {
        return this._title;
    }

    get component(): any {
        return this._component;
    }

    get noDataPolicy(): any {
        return this._noDataPolicy;
    }

    get defaultUiParams(): any {
        return this._defaultUiParams;
    }

    get defaultParams(): (vizDef) => { size: number } {
        return this._defaultParams;
    }

    get specResolver(): any {
        return this._specResolver;
    }

    get prepareViz(): any {
        return this._prepareViz;
    }

  get prepareData() {
    return this._prepareData;
  }
    get editable(): boolean {
        return this._editable;
    }

    get editor(): any {
        return this._editor;
    }

    get name(): string {
        return this._name;
    }
}

class VisualizationTypes {
    private _types: VisualizationType[];
    private typesByName: { [key: string]: VisualizationType };

    constructor() {
        this._types = [];
        this.typesByName = {};
    }

    add(type) {
        if (_.isNil(type)) {
            throw new Error(`Cannot register nil visualization type`);
        }
        if (_.has(this.typesByName, type.name)) {
            throw new Error(`Visualization type ${type.name} is already registered`);
        }

        this._types.push(type);
        this.typesByName[type.name] = type;
    }

    get(name) {
        return this.typesByName[name];
    }

    exists(name) {
        return _.has(this.typesByName, name);
    }
    get types(){
        return this._types;
    }
}

export const visualizationTypes = new VisualizationTypes();