import { Enum, EnumValue } from "../../utils/enum";
import { Tonality } from '@eptica/vecko-js-commons';
import { TFunction } from "i18next";

interface Option {
    hasNeutral?: boolean;
    alias?: () => SatType;
}

export class SatType extends EnumValue {
    readonly min: number;
    readonly max: number;
    readonly middle: number;
    readonly icon: string;
    readonly scoreMax: number;
    readonly nbOfDecimal: number;
    private readonly _hasNeutral: boolean
    private readonly aliasProvider: () => SatType;

    constructor(min: number, max: number, scoreMax: number, icon: string, decimal:number, options: Option = {
        hasNeutral: true,
        alias: undefined
    }) {
        super();
        this.min = min;
        this.max = max;
        this.scoreMax = scoreMax;
        this.middle = ((max - min) / 2) + min;
        this.icon = icon;
        this._hasNeutral = options.hasNeutral;
        this.aliasProvider = options.alias;
        this.nbOfDecimal= decimal;
    }

    getTonality(value: number): Tonality {
        return value < this.middle ? Tonality.NEGATIVE : Tonality.POSITIVE;
    }

    getLabel(t: TFunction): string {
        return t(`satType.${this.getAliasedName()}`);
    }

    getIcon(): string {
        return this.icon;
    }

    getAliasedName(): string {
        const alias = this._getAliasInstance();
        if (alias) {
            return alias.getAliasedName();
        }
        return this.name;
    }

    get hasNeutral(): boolean {
        const alias = this._getAliasInstance();
        if (alias) {
            return alias.hasNeutral;
        }
        return this._hasNeutral;
    }

    /**
     * @return {SatType}
     * @private
     */
    _getAliasInstance(): SatType | undefined {
        if (this.aliasProvider) {
            return this.aliasProvider();
        }
        return undefined;
    }
}

class SatTypeEnumType extends Enum<SatType> {
    NPS = new SatType(-100, 100, 10, 'people', 2);
    CSAT = new SatType(0, 100, 5, 'person', 2, {hasNeutral: false});
    CSAT_AVG = new SatType(1, 5, 5, 'person', 0, {alias: () => SatTypeEnum.CSAT});
    CSAT10 = new SatType(0, 100, 10, 'person', 2, {alias: () => SatTypeEnum.CSAT});
    CSAT10_AVG = new SatType(0, 10, 10, 'person', 0, {alias: () => SatTypeEnum.CSAT});
    CES = new SatType(1, 5, 5, 'walk', 2, {hasNeutral: false});
    CES10 = new SatType(0, 10, 10, 'walk', 2, {alias: () => SatTypeEnum.CES});

    constructor() {
        super();
        this.initEnum('SatType');
    }
}

export const SatTypeEnum = new SatTypeEnumType();