export default {
    translations: {
        'common.yes': 'Oui',
        'common.no': 'Non',
        'application.copyright': '© Vecko 2021',

        'globalSatisfaction.POSITIVE': 'Positive',
        'globalSatisfaction.NEUTRAL': 'Neutre',
        'globalSatisfaction.NEGATIVE': 'Negative',
        'globalSatisfaction.MIXED': 'Mitigé',

        'system.tenant': 'Tenant',
        'system.substream': 'Sourcing id',

        'channelKind': 'Canal',
        'channelKind.CHAT': 'Chat',
        'channelKind.EMAIL': 'E-mail',
        'channelKind.SURVEY': 'Enquête',
        'channelKind.FACEBOOK': 'Facebook',
        'channelKind.PHONE': 'Téléphone',
        'channelKind.WEBMAIL': 'Formulaire',
        'channelKind.invalid': '{{channelKindFieldLabel}} non valid : {{channelKind}}',
        'channelKind.empty': '{{channelKindFieldLabel}} non renseigné',
        'noResult': 'Aucun résultat',
        'customerJourney': 'Parcours client',
        'topic': 'Thématique',
        'topics': 'Thématiques',
        'filter': 'Filtrer',
        'filter.tag.notEmptyVerbatim': 'Exclure les verbatims vide',
        'dateRange.thisWeek': 'Cette semaine',
        'dateRange.lastWeek': 'La semaine dernière',
        'dateRange.thisMonth': 'Ce mois',
        'dateRange.lastMonth': 'Le mois dernier',
        'dateRange.thisQuarter': 'Ce trimestre',
        'dateRange.lastQuarter': 'Le trimestre dernier',
        'dateRange.thisYear': 'Cette année',
        'dateRange.lastYear': 'L\'année dernière',
        'dateRange.today': 'Aujourd\'hui',

        'chronoUnit.MILLIS': 'Millisecondes',
        'chronoUnit.SECONDS': 'Secondes',
        'chronoUnit.MINUTES': 'Minutes',
        'chronoUnit.HOURS': 'Heures',
        'chronoUnit.DAYS': 'Jours',

        'filter.title': 'Filtres',
        'filter.clearAll': 'Supprimer tous les filtres',
        'filter.category.value.none': 'Non categorisé',
        'filter.field.value.none': 'Non renseigné',

        'explore.title': 'Analyse',

        'topics.title': 'Parcours client',
        'topics.table.topic': 'Motif',
        'topics.table.volume': 'Volume sonore',
        'topics.all': 'Tous les motifs',
        'misc.feedback.count': '<0>{{count}}</0> <1>$t(misc.feedback)</1>',
        'misc.opinion.count': '<0>{{count}}</0> <1>$t(misc.opinion)</1>',

        'distributionKind': 'Distribution',
        'distributionKind.tonalities': 'Tonalité des avis',
        'distributionKind.tonalities.item': '$t(misc.opinion_plural) $t(common.Tonality.{{item}}_plural)',
        'distributionKind.satType': '$t(satType.{{satType}})',
        'distributionKind.satType.item': '$t(satType.{{satType}}.{{item}}_plural)',

        'analysis.title': 'Analyse en profondeur',
        'analysis.goTo': 'Aller à l\'analyse en profondeur',

        'feedbacks.conversations': '{{count}} $t(misc.feedback)',
        'feedbacks.export.tooltip': 'Exporter les 500 premières $t(misc.feedback_plural)',
        'feedbacks.delete.button': 'Suppression des feedbacks',
        'feedbacks.delete.request.success': 'Demande de suppression envoyée avec succès.',
        'feedbacks.delete.confirm.title': 'Êtes-vous sûr de vouloir supprimer ces feedbacks.',
        'feedbacks.delete.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning): Tous les feedbacks correspondant à la recherche seront supprimés!',
        'feedbacks.export.dialog.title': 'Export des conversations',
        'feedbacks.export.dialog.outputType': 'Format de sortie',
        'feedbacks.export.dialog.maxSize': 'Nombre maximum de conversation',
        'feedbacks.export.dialog.fields.system': 'Champs systèmes',
        'feedbacks.export.dialog.fields.configFields': 'Champs de configuration',
        'administration.export.feedbacks.fields': 'Champs à exporter',

        'kpi.countByCategories.label': 'pourcentage des $t(misc.conversation_plural)',
        'kpi.countByCategories.number': '{{count}}',
        'kpi.countByCategories.legend': '$t(misc.conversation) (soit {{percent}} %)',
        'kpi.countByTonalityAndCategories.label': '$t(misc.opinion_plural) $t(common.Tonality.{{tonality}}_plural)',
        'kpi.countByTonalityAndCategories.number': '{{count}} %',
        'kpi.countByTonalityAndCategories.legend': 'd\'$t(misc.opinion_plural) $t(common.Tonality.{{tonality}}_plural)',
        'kpi.satImpactByCategories.label': 'Impact sur $t(satType.{{satType}}.short.article)$t(satType.{{satType}}.short)',
        'kpi.satImpactByCategories.number': '$t(misc.signedValue) $t(misc.unit.point)',
        'kpi.satImpactByCategories.legend': 'somme de l\'impact sur $t(satType.{{satType}}.short.article)$t(satType.{{satType}}.short)',
        'kpi.countBySatPopulationAndCategories.label': '$t(satType.{{satType}}.{{satMood}}_plural)',
        'kpi.countBySatPopulationAndCategories.number': '{{count}} %',
        'kpi.countBySatPopulationAndCategories.legend': 'de $t(satType.{{satType}}.{{satMood}}_plural)',
        'kpi.satByCategories.label': '$t(satType.{{satType}})',
        'kpi.satByCategories.number': '{{count}}',
        'kpi.satByCategories.legend': '$t(satType.{{satType}})',

        'misc.feedback': 'conversation',
        'misc.feedback_plural': 'conversations',
        'misc.opinion': 'avis',
        'misc.opinion_plural': 'avis',
        'misc.conversation': 'conversation',
        'misc.conversation_plural': 'conversations',
        'misc.signedValue_negative': '{{count}}',
        'misc.signedValue_positive': '+{{count}}',
        'misc.unit.point': 'point',
        'misc.unit.point_plural': 'points',
        'misc.percent': '<0>{{count}}</0> <1>%</1>',

        'misc.search.pagination.currentPage': 'page {{current}}/{{pagesCount}}',
        'misc.search.noResult': 'Aucun résultat',


        'dashboard': 'tableau de bord',
        'dashboard_plural': 'Tableaux de bord',
        'my_dashboards': 'Mes tableaux de bord',

        'report': 'rapport',
        'report_plural': 'rapports',

        'dashboard.viz.topicImpact.title': "Impact des topics <0>{{polarity}}</0> sur $t(satType.{{satType}}.short.article)$t(satType.{{satType}}.short)",
        'dashboard.viz.topicImpact.header.topic': "$t(dashboard.misc.topic_plural)",
        'dashboard.viz.topicImpact.header.impact': "impact sur $t(satType.{{satType}}.short.article)$t(satType.{{satType}}.short)",
        'dashboard.viz.topicImpact.header.count': "conversations",

        'dashboard.viz.satScore.title': "$t(satType.{{satType}}) ({{subtitle}})",
        'dashboard.viz.satScore.title_noSubtitle': "$t(satType.{{satType}})",
        'dashboard.viz.satScore.count': "participants",

        'dashboard.viz.satScore.edit-dialog.title.period': " (Période {{index}})",
        'dashboard.viz.satScore.edit-dialog.participants': "Nombre de participants: ",
        'dashboard.viz.satScore.edit-dialog.count': "Score",
        'dashboard.viz.satScore.edit-dialog.trend': "Tendance",
        'dashboard.viz.satScore.edit-dialog.distribution.neutral': "Nombre de conversations neutres",
        'dashboard.viz.satScore.edit-dialog.distribution.positive': "Nombre de conversations positives",
        'dashboard.viz.satScore.edit-dialog.distribution.negative': "Nombre de conversations négatives",

        'dashboard.viz.satEvolution.title': 'Évolution $t(satType.{{satType}}.article.contracted)$t(satType.{{satType}})',
        'dashboard.viz.satEvolution.serie.global': '$t(satType.{{satType}}.short) global',
        'dashboard.viz.satEvolution.edit-dialog.score': 'Score',

        'dashboard.viz.volume.title': "Volume ({{subtitle}})",
        'dashboard.viz.volume.title_noSubtitle': "Volume",
        'dashboard.viz.volume.unit': "conversation",
        'dashboard.viz.volume.unit_plural': "conversations",

        'dashboard.viz.metric.title': '{{title}} ({{subtitle}})',
        'dashboard.viz.metric.title_noSubtitle': "{{title}}",
        'dashboard.viz.metric.editor.main': 'Valeur principale',
        'dashboard.viz.metric.editor.trend': 'Tendance',
        'dashboard.viz.metric.editor.secondary': 'Valeur secondaire',
        'dashboard.viz.metric.editor.percentageMode': 'Mode pourcentage',
        'dashboard.viz.metric.editor.showTrend': 'Afficher la tendance',
        'dashboard.viz.metric.editor.showSecondary': 'Afficher la valeur secondaire',
        'dashboard.viz.metric.editor.valueUnit': 'Unité',
        'dashboard.viz.metric.editor.valueUnit.override': 'Valeur personnalisée',
        'dashboard.viz.metric.editor.valueUnit.helperText': 'La valeur par défaut est :',
        'dashboard.viz.metric.editor.font': 'Police',
        'dashboard.viz.metric.editor.font.size': 'Taille (px)',
        'dashboard.viz.metric.editor.font.color': 'Couleur',

        'dashboard.viz.volumeEvolution.title': 'Évolution du volume',
        'dashboard.viz.volumeEvolution.serie.global': 'Volume global',

        'dashboard.viz.actionPlan.title': 'Plan d\'action',
        'dashboard.viz.actionPlan.overall': 'Score de {{satType}} ({{value}})',
        'dashboard.viz.actionPlan.axis.satisfaction': 'Satisfaction',
        'dashboard.viz.actionPlan.axis.frequency': 'Fréquence',
        'dashboard.viz.actionPlan.area.watch': 'Surveiller',
        'dashboard.viz.actionPlan.area.generalize': 'Généraliser',
        'dashboard.viz.actionPlan.area.enhance': 'Améliorer',
        'dashboard.viz.actionPlan.area.capitalize': 'Capitaliser',
        'dashboard.viz.actionPlan.editor.header.topic': '$t(dashboard.misc.topic_plural)',
        'dashboard.viz.actionPlan.editor.header.satisfaction': '$t(dashboard.viz.actionPlan.axis.satisfaction)',
        'dashboard.viz.actionPlan.editor.header.importance': '$t(dashboard.viz.actionPlan.axis.frequency)',
        'dashboard.viz.actionPlan.editor.automaticSelection': 'Sélection automatique',

        'dashboard.viz.topicImpactTopEvolution.title': "Top 3 des évolutions <0>$t(common.Tonality.{{tonality}}_feminine_plural)</0> sur $t(satType.{{satType}}.short.article)$t(satType.{{satType}}.short)",
        'dashboard.viz.topicImpactTopEvolution.contrib': "{{contrib}} points",
        'dashboard.viz.topicImpactTopEvolution.editor.title': 'Commentaires client <0>$t(common.Tonality.{{tonality}}_plural)</0>',

        'dashboard.viz.actionList.title': 'Leviers d\'Amélioration',
        'dashboard.viz.actionList.emptyLabel': 'Complétez à présent <br/>le <strong>Plan d\'Action</strong>',
        'dashboard.viz.actionList.editor.actionListNumber': 'Plan d\'action n°{{index}}',

        'dashboard.viz.customerToRecall.title': 'Clients à rappeler',

        'dashboard.viz.ranking.title': 'Classement des $t({{field}})',
        'dashboard.viz.ranking.header.value': 'note /{{unit}}',
        'dashboard.viz.ranking.header.count': 'conversations',
        'dashboard.viz.ranking.header.percentage': 'Pourcentage',
        'dashboard.viz.ranking.header.opinions': '$t(misc.opinion_plural)',
        'dashboard.viz.ranking.editor.metricRanges': 'Seuils',
        'dashboard.viz.ranking.editor.exclusions': 'Exclusions',
        'dashboard.viz.ranking.editor.inclusions': 'Inclusions',
        'dashboard.viz.ranking.editor.sort': 'Tri',
        'dashboard.viz.ranking.editor.display': 'Affichage',

        'dashboard.viz.customerComment.title': 'Commentaire d\'un <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.customerComment.editor.title': 'Commentaire d\'un <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.customerComment.editor.vizTitle': 'Titre d\'entête',
        'dashboard.viz.customerComment.editor.comment': 'Commentaire',
        'dashboard.viz.customerComment.editor.feedbacks': 'Verbatims',
        'dashboard.viz.customerComment.editor.customFields': 'Personnalisation',
        'dashboard.viz.customerComment.editor.headerFields': 'Champs d\'entête',

        'dashboard.viz.standardProfile.title': 'Profil type d\'un <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.standardProfile.editor.title': 'Profil type d\'un <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.standardProfile.editor.feedback': 'Verbatim',
        'dashboard.viz.standardProfile.editor.profile': 'Profil',
        'dashboard.viz.standardProfile.editor.profileItem': 'Elément de profil',
        'dashboard.viz.standardProfile.editor.feedbackTitle': 'Titre du verbatim',

        'dashboard.viz.tetraClasse.title': 'Asymétrie des contributions',
        'dashboard.viz.tetraClasse.header.topic': "$t(dashboard.misc.topic_plural)",
        'dashboard.viz.tetraClasse.header.x': 'Négatif/insatifsaction',
        'dashboard.viz.tetraClasse.header.y': 'Positif/satisfaction',
        'dashboard.viz.tetraClasse.header.classe': 'Classe',
        'dashboard.viz.tetraClasse.header.count': 'conversations',

        'dashboard.viz.topicSatScore.title.NPS.POSITIVE': 'Top 3 des motifs | Sources de promoteurs',
        'dashboard.viz.topicSatScore.title.NPS.NEGATIVE': 'Top 3 des motifs | Sources de détracteurs',
        'dashboard.viz.topicSatScore.title.CSAT.POSITIVE': 'Top 3 des motifs | Sources de satisfaction',
        'dashboard.viz.topicSatScore.title.CSAT.NEGATIVE': 'Top 3 des motifs | Sources d’insatisfaction',
        'dashboard.viz.topicSatScore.title.CES.POSITIVE': 'Top 3 des motifs | Effort faible',
        'dashboard.viz.topicSatScore.title.CES.NEGATIVE': 'Top 3 des motifs | Effort élevé',
        'dashboard.viz.topicSatScore.editor.verbatims': 'Verbatims',
        'dashboard.viz.topicSatScore.editor.params': 'Paramètres',
        'dashboard.viz.topicSatScore.editor.metricRanges': 'Seuils',
        'dashboard.viz.topicSatScore.editor.exclusions': 'Exclusions',
        'dashboard.viz.topicSatScore.editor.inclusions': 'Inclusions',
        'dashboard.viz.topicSatScore.editor.sort': 'Tri',

        'dashboard.viz.customerJourneySatScore.title': 'Performance par parcours',
        'dashboard.viz.customerJourneySatScore.editor.title': 'Performance par parcours',
        'dashboard.viz.customerJourneySatScore.editor.positiveThreshold': 'Définir positif à partir de',

        'dashboard.viz.distribution.title': 'Répartition - $t({{field}})',
        'dashboard.viz.distribution.editor.title': 'Répartition - $t({{field}})',
        'dashboard.viz.distribution.editor.size': 'Nombre de valeurs',
        'dashboard.viz.distribution.editor.display': 'Affichage',
        'dashboard.viz.distribution.editor.showPercent': 'Afficher le pourcentage',
        'dashboard.viz.distribution.editor.showVolume': 'Afficher le volume',
        'dashboard.viz.distribution.editor.showTrend': 'Afficher la tendance',
        'dashboard.viz.distribution.editor.color': 'Couleur',

        'dashboard.editor.distribution.title': 'Distribution',
        'dashboard.editor.distribution.enabled': 'Activer',
        'dashboard.editor.distribution.showNeutral': 'Afficher les neutres',
        'dashboard.editor.distribution.showPercent': 'Afficher le pourcentage',
        'dashboard.editor.distribution.showVolume': 'Afficher le volume',
        'dashboard.editor.distribution.distributionKind': 'Type',
        'dashboard.editor.distribution.showParticipants': 'Afficher le nombre de participants',

        'dashboard.misc.point': '<0>$t(misc.signedValue)</0> <1>$t(misc.unit.point)</1>',
        'dashboard.misc.polarity.positive': 'positif',
        'dashboard.misc.polarity.positive_plural': 'positifs',
        'dashboard.misc.polarity.negative': 'négatif',
        'dashboard.misc.polarity.negative_plural': 'négatifs',
        'dashboard.misc.polarity.positive_feminine': 'positive',
        'dashboard.misc.polarity.positive_feminine_plural': 'positives',
        'dashboard.misc.polarity.negative_feminine': 'négative',
        'dashboard.misc.polarity.negative_feminine_plural': 'négatives',
        'dashboard.misc.topic': 'topic',
        'dashboard.misc.topic_plural': 'topics',

        'dashboard.title': 'Rapport de Satisfaction Client',

        'dashboard.viz.mainSatScore.description.short': 'Ce score correspond au filtre sélectionné (période, type de score et champ spécifique). L\'évolution en bas du score tient compte de la même période passée, et les participants correspondent à toutes les notes de la colonne de score choisie.',
        'dashboard.viz.refSatScore.description.short': 'Ce score correspond au 2ème champ sélectionné (Le champ auquel on souhaite se comparer).',
        'dashboard.viz.globalSatScore.description.short': 'Ce score est global, avec pour seul filtre la période de temps choisie.',
        'dashboard.viz.satEvolution.description.short': 'Ce graphique indique l\'évolution du score choisi, en indiquant le score de chaque mois.',
        'dashboard.viz.bestTopicsRanking.description.short': 'Les motifs affichés ici sont ceux qui ont le score le plus élevé, ainsi que le nombre d\'avis positifs le plus élevé.',
        'dashboard.viz.worstTopicsRanking.description.short': 'Les motifs affichés ici sont ceux qui ont le score le moins élevé et le nombre d\'avis négatifs le plus élevé.',
        'dashboard.viz.positiveTopicImpact.description.short': 'Les motifs affichés ici sont ceux qui, à chaque fois qu\'ils sont mentionnés dans les verbatims, sont liés à une note positive. Ce sont ceux qui ont donc une influence positive sur la note globale.',
        'dashboard.viz.negativeTopicImpact.description.short': 'Les motifs affichés ici sont ceux qui - à chaque fois qu\'ils sont mentionnés dans les verbatims - sont liés à une note négative. Ce sont ceux qui ont donc une influence négative sur la note globale.',
        'dashboard.viz.customerJourneySat.description.short': 'Ce graphique permet de comparer les catégories du parcours client en fonction de leur score. Le score affiché correspond à la note globale de tous les clients ayant laissé un commentaire sur un des motifs de cette catégorie.',
        'dashboard.viz.customersToRecall.description.short': 'Ce graphique indique le nombre de clients ayant laissé un commentaire sur un des indicateurs de risque. Pour avoir le détail de ces verbatims, il faut aller dans la section "Conversations".',
        'dashboard.viz.positiveTopicImpactTopEvolution.description.short': 'Les 3 motifs affichés ici sont ceux dont l\'impact positif sur le score global a le plus évolué, comparé à la période passée.',
        'dashboard.viz.negativeTopicImpactTopEvolution.description.short': 'Les 3 motifs affichés ici sont ceux dont l\'impact négatif sur le score global a le plus évolué.',
        'dashboard.viz.actionList.description.short': 'Ce graphique donne la possibilité de rédiger au maximum 3 brefs énoncés de plans d\'action ou de faits remarquables.',
        'dashboard.viz.mainRanking.description.short': 'Ce graphique permet de comparer des items, compte tenu de leurs scores, du volume de conversations et du nombre d\'avis positifs ou négatifs.',
        'dashboard.viz.refRanking.description.short': 'Ce graphique permet de comparer les items du 2ème champ sélectionné (le champ auquel on souhaite se comparer).',
        'dashboard.viz.promoterCustomerComment.description.short': 'Il est possible ici de choisir un verbatim et d\'ajouter un titre et un commentaire d\'un client satisfait.',
        'dashboard.viz.detractorCustomerComment.description.short': 'Il est possible ici de choisir un verbatim et d\'ajouter un titre et un commentaire d\'un client insatisfait.',
        'dashboard.viz.globalSatEvolution.description.short': 'Le score ici est global avec pour seul filtre la période de temps sélectionnée.',
        'dashboard.viz.mainVolume.description.short': 'Ce graphique indique le volume de verbatims qui correspond au filtre sélectionné.',
        'dashboard.viz.volumeEvolution.description.short': 'Ce graphique indique l\'évolution du volume de verbatims, comparé à l\'évolution du volume global de tous les verbatims.',
        'dashboard.viz.actionPlan.description.short': 'Ce graphique répartit les motifs en fonction de leur importance sur la note globale et de leur satisfaction (positive ou négative). L\'importance, sur l\'axe vertical, désigne le volume de conversations du motif concerné. La catégorie "Améliorer" correspond aux motifs qui impactent fortement le score, de façon négative. "Capitaliser" est celle des motifs qui impactement fortement de façon positive. "Surveiller" correspond à des motifs qui impactent peu et de façon négative le score. "Généraliser" est la catégorie qui inclut les motifs ayant un impact positif et faible.',
        'dashboard.viz.distributionEaseOfContact.description.short': 'Ce graphique indique la répartition de la satisfaction pour la question posée aux clients.',
        'dashboard.viz.distributionDelayContact.description.short': 'Ce graphique indique la répartition de la satisfaction pour la question posée aux clients.',
        'dashboard.viz.distributionReception.description.short': 'Ce graphique indique la répartition de la satisfaction pour la question posée aux clients.',
        'dashboard.viz.distributionNeeds.description.short': 'Ce graphique indique la répartition de la satisfaction pour la question posée aux clients.',
        'dashboard.viz.distributionResponse.description.short': 'Ce graphique indique la répartition de la satisfaction pour la question posée aux clients.',
        'dashboard.viz.distributionAdvice.description.short': 'Ce graphique indique la répartition de la satisfaction pour la question posée aux clients.',

        'dashboard.viz.distributionChart.group.other': 'Autres',
        'dashboard.viz.pieChart.tooltip.field': 'Champ',
        'dashboard.viz.pieChart.tooltip.value': 'Valeur',
        'dashboard.viz.pieChart.tooltip.count': 'Volume',
        'dashboard.viz.pieChart.tooltip.percentage': '%',
        'dashboard.viz.distributionChart.tooltip.count': 'Nombre',

        'dashboard.viz.table.volume': 'Volume',
        'dashboard.viz.table.opinions': 'Opinions',
        'dashboard.viz.table.distribution': 'Distribution',
        'dashboard.viz.table.intent': 'Intent',
        'dashboard.viz.table.trend': 'Trend',
        'dashboard.viz.table.satScore': 'Score',
        'dashboard.viz.table.tonalities': 'Tonalities',

        'rangeEditor.min': 'Min',
        'rangeEditor.max': 'Max',
        'rangeEditor.error.minMax': 'La valeur doit être comprise entre {{min}} et {{max}}',
        'rangeEditor.error.min': 'La valeur doit être supérieure ou égale à {{min}}',
        'rangeEditor.error.max': 'La valeur doit être inférieure ou égale à {{max}}',

        'action.add': 'Ajouter',
        'action.export': 'Exporter',
        'action.cancel': 'Annuler',
        'action.cancelAll': 'Annuler tout',
        'action.validate': 'Valider',
        'action.apply': 'Valider',
        'action.delete': 'Supprimer',
        'action.deleteAll': 'Supprimer tout',
        'action.showMore': 'Afficher plus ...',
        'action.showLess': 'Afficher moins ...',
        'action.search': 'Rechercher',
        'action.all': 'Tous',
        'action.selectAll': 'Tout sélectionner',
        'action.clearSelection': 'Effacer la sélection',
        'action.invertSelection': 'Inverser selection',
        'action.close': 'Fermer',
        'action.expandAll': 'Développer tous les noeuds',
        'action.collapseAll': 'Réduire tous les noeuds',
        'action.refresh': 'Rafraichir',
        'action.yes': 'Oui',
        'action.no': 'Non',
        'action.save': 'Enregistrer',
        'action.restore': 'Restaurer',
        'action.replay': 'Relancer',
        'action.replayAll': 'Relancer tout',

        'confirm.confirmInputLabel': 'Pour confirmer cette action, vous devez saisir "{{confirmText}}"',

        'form.required': 'obligatoire',

        'input.password.placeholder': 'Saisissez un mot de passe ...',
        'input.password.show': 'Voir le mot de passe',
        'input.password.hide': 'Cacher le mot de passe',

        'dashboard.viz.noData': 'Aucune information détectée dans les conversations',
        'dashboard.viz.noData.light': 'Aucun résultat',
        'dashboard.viz.unknownValue': 'Motif inconnu',

        'export.highlight.title': 'Exporter les highlights',
        'export.highlight.param.max.label': 'Maximum',
        'export.highlight.param.max.helper': 'Nombre maximum de feedback à exporter...',
        'export.highlight.param.categoryTree.label': 'Arbre de catégorisation',
        'export.highlight.param.categoryTree.helper': 'Arbre de catégorisation à highlighter...',
        'export.highlight.param.tonality.label': 'Tonalité',
        'export.highlight.param.tonality.helper': 'Tonalité des offsets à highlighter...',
        'export.highlight.param.lang.label': 'Langue',
        'export.highlight.param.lang.helper': 'Langue dans laquelle sera traduit l\'arbre de catégories',

        'period.month': 'De {{from}} à {{to}}',
        'period.day': 'Du {{from}} au {{to}}',
        'period.week.this': 'Cette semaine',
        'period.week.last': 'La semaine dernière',
        'period.week': 'Semaine {{week}}',
        'period.quarter.1': 'Premier trimestre',
        'period.quarter.2': 'Deuxième trimestre',
        'period.quarter.3': 'Troisième trimestre',
        'period.quarter.4': 'Quatrième trimestre',
        'period.kind.WEEK': 'Hebdomadaire',
        'period.kind.MONTH': 'Mensuelle',
        'period.kind.QUARTER': 'Trimestrielle',
        'period.kind.12MONTHS': '12 derniers mois',

        'satType': 'Type de satisfaction',

        'satType.NPS': 'Net Promoter Score',
        'satType.NPS.article': 'le ',
        'satType.NPS.article.contracted': 'du ',
        'satType.NPS.short': 'NPS',
        'satType.NPS.short.article': 'le ',
        'satType.NPS.short.article.contracted': 'du ',
        'satType.NPS.POSITIVE': 'promoteur',
        'satType.NPS.POSITIVE_plural': 'promoteurs',
        'satType.NPS.NEGATIVE': 'détracteur',
        'satType.NPS.NEGATIVE_plural': 'détracteurs',
        'satType.NPS.NEUTRAL': 'passif',
        'satType.NPS.NEUTRAL_plural': 'passifs',

        'satType.CSAT': 'Satisfaction client',
        'satType.CSAT.article': 'la ',
        'satType.CSAT.article.contracted': 'de la ',
        'satType.CSAT.short': 'CSAT',
        'satType.CSAT.short.article': 'le ',
        'satType.CSAT.short.article.contracted': 'du ',
        'satType.CSAT.POSITIVE': 'client satisfait',
        'satType.CSAT.POSITIVE_plural': 'clients satisfaits',
        'satType.CSAT.NEGATIVE': 'client insatisfait',
        'satType.CSAT.NEGATIVE_plural': 'clients insatisfaits',

        'satType.CES': 'Effort client',
        'satType.CES.article': 'l\'',
        'satType.CES.article.contracted': 'de l\'',
        'satType.CES.short': 'CES',
        'satType.CES.short.article': 'le ',
        'satType.CES.short.article.contracted': 'du ',
        'satType.CES.POSITIVE': 'effort faible',
        'satType.CES.POSITIVE_plural': 'efforts faibles',
        'satType.CES.NEGATIVE': 'effort élevé',
        'satType.CES.NEGATIVE_plural': 'efforts élevés',

        'metric.SAT_SCORE': 'Score de satisfaction',
        'metric.VOLUME': 'Volume',
        'metric.VOLUME_TREND': 'Tendance du volume',
        'metric.PERCENTAGE': 'Poids',
        'metric.TREND': 'Tendance du score',
        'metric.OPINIONS': '$t(misc.opinion_plural) $t(common.Tonality.{{tonality}}_plural)',
        'metric.OPINIONS_FEEDBACK': '$t(misc.feedback_plural) $t(common.Tonality.{{tonality}}_plural)',
        'metric.POSITIVE_OPINIONS': '$t(misc.opinion_plural) $t(common.Tonality.POSITIVE_plural)',
        'metric.NEGATIVE_OPINIONS': '$t(misc.opinion_plural) $t(common.Tonality.NEGATIVE_plural)',

        'topicStatDetailViewMode.FEEDBACKS': '$t(misc.feedback_plural)',
        'topicStatDetailViewMode.SEQUENCES': 'Séquences',

        'sortOrder': 'Ordre',
        'sortOrder.ASC': 'Ascendant',
        'sortOrder.DESC': 'Descendant',

        'tetraClasse.CLE': 'Clé',
        'tetraClasse.PLUS': 'Plus',
        'tetraClasse.SECONDAIRE': 'Secondaire',
        'tetraClasse.BASIQUE': 'Basique',

        'lang.fr': 'Français',
        'lang.en': 'Anglais',

        'error.runtime': 'Une erreur est survenue',
        'error.http': 'Une erreur est survenue lors d\'un appel au serveur',
        'error.http.authorization': 'Opération non autorisée',
        'error.http.dashboardExport.initiateExport': 'Impossible d\'exporter le rapport',
        'error.http.feedback.getFeedback': 'Impossible de trouver le feedback avec ID : {{args.0}}',
        'error.http.storage.uploadStorageFile.415': 'Type de fichier non supporté',
        'error.http.configuration.getFieldsConfiguration': 'Impossible de charger la configuration',
        'error.application.load': 'Impossible d\'initialiser vecko',
        'error.404.title': 'PAGE INTROUVABLE',
        'error.404.description': 'La page que vous recherchez n\'existe pas ou vous n\'êtes pas autorisé à la consulter.',
        'error.404.backToHome': 'Retour à l\'accueil',

        'feedback.debug.operations': 'Journal des opérations',
        'feedback.debug.source': 'Source',
        'feedback.debug.source.api': 'Api',
        'feedback.debug.source.audio': 'Audio',
        'feedback.debug.source.transcribed': 'Transcribed',
        'feedback.debug.source.original': 'Original',
        'feedback.debug.source.raw': 'Raw',
        'feedback.debug.source.analyzed': 'Analyzed',
        'feedback.debug.source.indexed': 'Indexed',
        'feedback.debug.source.summary': 'Summary',
        'feedback.debug.categorizationRules': 'Categorization - Règles',
        'feedback.debug.categorizationDetails': 'Categorization - Détail',

        'storageKind.FEEDBACK_MIRRORED': 'Fichiers récents',
        'storageKind.FEEDBACK_DEMO': 'Fichiers de démo',

        'administration': 'Administration',
        'administration.system': 'Système',
        'administration.system.cache': 'Cache',
        'administration.system.cache.local': 'Caches locaux',
        'administration.system.cache.local.description': 'Les caches mémoires sont des caches avec de courtes durées de vie (quelques minutes à quelques jours). Les données mises dans ce types de cache sont des données fréquemment lues mais dont la perte n\'engendre pas de conséquences. Parmi les données cachées dans les caches mémoires se trouvent par exemple les urls des lingwares et leur versions.',
        'administration.system.cache.persistent': 'Caches persistants',
        'administration.system.cache.persistent.description': 'Les caches persistant sont des caches dont les données sont stockées en base de données. L\'arrêt de l\'application n\'a pas d\'effets sur type de cache. On y stock généralement de données dont la construction ou l\'accès sont coûteux. Parmi les données mise en cache dans les caches persistants se trouvent les résultats d\'obfuscation, d\'analyses linguistiques, de détections de langues, d\'extraction de partie significative ou de transcription audio.',
        'administration.system.cache.local.invalidate': 'Invalider',
        'administration.system.cache.persistent.invalidate': 'Invalider pour le tenant courant',
        'administration.system.cache.persistent.invalidate.multiTenant': 'Invalider pour tous les tenants',
        'administration.system.cache.invalidation.success': 'Invalidation effectuées avec succès.',
        'administration.system.corpus': 'Corpus',
        'administration.system.corpus.tenantInfo': 'Informations générales',
        'administration.system.corpus.tenantInfo.tenant': 'Tenant',
        'administration.system.corpus.tenantInfo.tenant.name': 'Nom',
        'administration.system.corpus.tenantInfo.tenant.id': 'Id',
        'administration.system.corpus.tenantInfo.reindex': 'Reindexer',
        'administration.system.corpus.tenantInfo.tenant.admin': 'admin',
        'administration.system.corpus.tenantInfo.cassandra': 'Cassandra',
        'administration.system.corpus.tenantInfo.cassandra.keyspace': 'Keyspace',
        'administration.system.corpus.tenantInfo.cassandra.datacenter': 'Datacenter',
        'administration.system.corpus.tenantInfo.idp': 'IDP',
        'administration.system.corpus.tenantInfo.idp.realm': 'Realm',
        'administration.system.corpus.tenantInfo.idp.clientId': 'Client id',
        'administration.system.corpus.tenantInfo.idp.link': 'admin',
        'administration.system.corpus.feedbackStats': 'Décompte des feedbacks',
        'administration.system.corpus.feedbackStats.empty': 'Il n\'y a aucun feedback',
        'administration.system.corpus.feedbackStats.date': 'Date',
        'administration.system.corpus.feedbackStats.total': 'Total',
        'administration.system.corpus.feedbackStats.categorized': 'Catégorisé',
        'administration.system.corpus.feedbackStats.notEmpty': 'Non vide',
        'administration.system.corpus.feedbackStats.coverage': 'Couverture',
        'administration.system.purge': 'Purge de données',
        'administration.system.purge.launch': 'Lancer la purge',
        'administration.system.purge.by_date': 'Purge des données de feedback par date',
        'administration.system.purge.by_date.button-label': 'Purger par date',
        'administration.system.purge.by_date.confirmation-message': 'Êtes-vous sûr de vouloir purger les données de feedback par date?',
        'administration.system.purge.by_date.request.success': 'Demande de purge par date envoyée avec succès.',
        'administration.system.purge.by_duration': 'Purge des données de feedback par durée',
        'administration.system.purge.by_duration.button-label': 'Purger par durée',
        'administration.system.purge.by_duration.request.success': 'Demande de purge par durée envoyée avec succès.',
        'administration.system.purge.by_duration.confirmation-message': 'Êtes-vous sûr de vouloir purger les données de feedback par durée?',
        'administration.system.purge.by_duration.nb_days': 'jours',
        'administration.system.purge.by_duration.nb_months': 'mois',
        'administration.system.purge.by_duration.nb_years': 'années',
        'administration.system.purge.by_configuration': 'Purge des données de feedback par configuration',
        'administration.system.purge.by_configuration.button-label': 'Purger par configuration',
        'administration.system.purge.by_configuration.actual-configuration': 'Configuration actuelle du tenant',
        'administration.system.purge.by_configuration.request.success': 'Demande de purge par configuraiton envoyée avec succès.',
        'administration.system.purge.by_configuration.confirmation-message': 'Êtes-vous sûr de vouloir purger les données de feedback par configuration?',
        'administration.system.purge.all': 'Purge complète des données de feedback ',
        'administration.system.purge.all.button-label': 'Purger tous les feedback',
        'administration.system.purge.all.request.success': 'Demande de purge complète envoyée avec succès.',
        'administration.system.purge.all.confirmation-message': 'Êtes-vous sûr de vouloir purger toutes les données de feedback?',
        'administration.system.purge.errors': 'Purge des errors de sourcing',
        'administration.system.purge.errors.button-label': 'Purger',
        'administration.system.purge.errors.confirmation-message': 'Êtes-vous sûr de vouloir purger les erreurs de sourcing?',
        'administration.system.purge.errors.request.success': 'Demande de purge des erreurs de sourcing envoyée avec succès.',
        'administration.sourcing': 'Sourcing',
        'administration.sourcing.storage.demo': '$t(storageKind.FEEDBACK_DEMO)',
        'administration.sourcing.storage.mirrored': '$t(storageKind.FEEDBACK_MIRRORED)',
        'administration.sourcing.storage.sourcePath': 'Sourcer',
        'administration.sourcing.storage.sourceSelection': 'Sourcer la sélection',
        'administration.sourcing.storage.source.result.none': 'Aucun fichier envoyé au sourcing',
        'administration.sourcing.storage.source.result.one': 'Fichier "{{source}}" envoyé au sourcing : {{count}} feedbacks',
        'administration.sourcing.storage.source.result.many': '{{count}} fichiers envoyés au sourcing : {{total}} feedbacks',
        'administration.sourcing.storage.uploadFile': 'Ajouter un fichier',
        'administration.sourcing.storage.uploadFile.progress': 'Ajout en cours',
        'administration.sourcing.storage.uploadFile.success': 'Fichier "{{file}}" ajouté avec succès',
        'administration.sourcing.storage.deletePath': 'Supprimer',
        'administration.sourcing.storage.deleteSelection': 'Supprimer la sélection',
        'administration.sourcing.storage.deletePaths.confirm': 'Êtes-vous sûr de vouloir supprimer ? Cette action est irréversible !',
        'administration.sourcing.storage.delete.failure': 'Un élément n\'a pas pu être supprimé',
        'administration.sourcing.storage.delete.failure_plural': '{{count}} éléments n\'ont pas pu être supprimé',
        'administration.sourcing.storage.downloadFile': 'Télécharger',
        'administration.sourcing.storage.downloadSelection': 'Télécharger la sélection',
        'administration.sourcing.storage.noFiles': 'Aucun fichiers',
        'administration.sourcing.csv.options.label': 'Options',
        'administration.sourcing.csv.options.charset.label': 'Encodage du fichier',
        'administration.sourcing.csv.options.delimiterChar.label': 'Séparateur',
        'administration.sourcing.csv.options.quoteChar.label': 'Guillemets',

        'administration.sourcing.local': 'Fichiers locaux',
        'administration.sourcing.local.xlsx': 'Fichier XLSX',
        'administration.sourcing.local.csv': 'Fichier CSV',
        'administration.sourcing.local.json': 'Fichier JSON',
        'administration.sourcing.local.audio': 'Fichier audio',
        'administration.sourcing.local.audio.audioFile.helper': 'Fichier audio. Formats supportés: {{formats}}',
        'administration.sourcing.local.audio.audioFile.label': 'Fichier audio',
        'administration.sourcing.local.audio.audioMetadata.helper': 'Paramètres du fichiers audio qui va permettre la transcription',
        'administration.sourcing.local.audio.audioMetadata.label': 'Paramètres du fichier audio',
        'administration.sourcing.local.audio.audioMetadata.language': 'Language',
        'administration.sourcing.local.audio.audioMetadata.audioFormat': 'Encodage',
        'administration.sourcing.local.audio.audioMetadata.sampleRate': 'Sample rate (Hz)',
        'administration.sourcing.local.audio.audioMetadata.callDirection': 'Direction de l\'appel',
        'administration.sourcing.local.audio.audioMetadata.channels': 'Canaux',
        'administration.sourcing.local.audio.audioMetadata.channels.mono': 'Mono',
        'administration.sourcing.local.audio.audioMetadata.channels.stereo': 'Stéréo',
        'administration.sourcing.local.audio.metadata.helper': 'Fichier de métadonnées qui seront attachées au feedback',
        'administration.sourcing.local.audio.metadata.label': 'Metadonnées',
        'administration.sourcing.local.file.label': 'Fichier',
        'administration.sourcing.local.file.text': 'Choisissez un fichier ...',
        'administration.sourcing.local.source': 'Sourcer',
        'administration.sourcing.local.source.success': 'Fichier envoyé au sourcing',

        'administration.sourcing.progress': 'Progression',
        'administration.sourcing.progress.none': 'Aucun sourcing en cours',
        'administration.sourcing.progress.count.tenants': 'Tenants',
        'administration.sourcing.progress.count.sourcing': 'Sourcings',
        'administration.sourcing.progress.count.emptySourcing': 'Sourcings vides',
        'administration.sourcing.progress.count.inputFeedbacks': 'Feedbacks entrants',
        'administration.sourcing.progress.count.indexedFeedbacks': 'Feedbacks indexés',
        'administration.sourcing.progress.dangerousActionWarning': 'Attention, cette action est irréversible',
        'administration.sourcing.progress.delete.success': 'Le sourcing a été supprimé',
        'administration.sourcing.progress.delete.confirm': 'Êtes-vous sûr de vouloir supprimer ce sourcing.',
        'administration.sourcing.progress.delete.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning) : Toutes les données seront supprimées !',
        'administration.sourcing.progress.deleteAll.success': 'Tous les sourcings ont été supprimés',
        'administration.sourcing.progress.deleteAll.confirm.all': 'Êtes-vous sûr de vouloir supprimer tous les sourcings de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. Un sourcing sera supprimé.',
        'administration.sourcing.progress.deleteAll.confirm.all_plural': 'Êtes-vous sûr de vouloir supprimer tous les sourcings de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. {{count}} sourcings seront supprimés.',
        'administration.sourcing.progress.deleteAll.confirm.one': 'Êtes-vous sûr de vouloir supprimer tous les sourcings du tenant {{tenant}} ? Un sourcing sera supprimé.',
        'administration.sourcing.progress.deleteAll.confirm.one_plural': 'Êtes-vous sûr de vouloir supprimer tous les sourcings du tenant {{tenant}} ? {{count}} sourcings seront supprimés.',
        'administration.sourcing.progress.deleteAll.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning) : Toutes les données seront supprimées !',
        'administration.sourcing.progress.cancel.success': 'Le sourcing a été annulé',
        'administration.sourcing.progress.cancelAll.success': 'Tous les sourcings ont été annulés',
        'administration.sourcing.progress.cancelAll.confirm.all': 'Êtes-vous sûr de vouloir annuler tous les sourcings de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. Un sourcing sera annulé.',
        'administration.sourcing.progress.cancelAll.confirm.all_plural': 'Êtes-vous sûr de vouloir annuler tous les sourcings de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. {{count}} sourcings seront annulés.',
        'administration.sourcing.progress.cancelAll.confirm.one': 'Êtes-vous sûr de vouloir annuler tous les sourcings du tenant {{tenant}} ? Un sourcing sera annulé.',
        'administration.sourcing.progress.cancelAll.confirm.one_plural': 'Êtes-vous sûr de vouloir annuler tous les sourcings du tenant {{tenant}} ? {{count}} sourcings seront annulés.',
        'administration.sourcing.progress.replay.success': 'Le sourcing a été relancé',
        'administration.sourcing.progress.replayAll.success': 'Tous les sourcing ont été relancés',
        'administration.sourcing.progress.replayAll.confirm.all': 'Êtes-vous sûr de vouloir relancer tous les sourcings de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. Un sourcing sera relancé.',
        'administration.sourcing.progress.replayAll.confirm.all_plural': 'Êtes-vous sûr de vouloir relancer tous les sourcings de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. {{count}} sourcings seront relancés.',
        'administration.sourcing.progress.replayAll.confirm.one': 'Êtes-vous sûr de vouloir relancer tous les sourcings du tenant {{tenant}} ? Un sourcing sera relancé.',
        'administration.sourcing.progress.replayAll.confirm.one_plural': 'Êtes-vous sûr de vouloir relancer tous les sourcings du tenant {{tenant}} ? {{count}} sourcings seront relancés.',
        'administration.sourcing.progress.cleanup': 'Nettoyer',
        'administration.sourcing.progress.cleanup.success': 'Les sourcings sans feedbacks ont été supprimés',
        'administration.sourcing.progress.cleanup.confirm.all': 'Êtes-vous sûr de vouloir supprimer les sourcings sans feedback de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. Un sourcing sera supprimé.',
        'administration.sourcing.progress.cleanup.confirm.all_plural': 'Êtes-vous sûr de vouloir supprimer les sourcings sans feedback de tous les tenants ? Cette action va impacter {{tenantsCount}} tenants. {{count}} sourcings seront supprimés.',
        'administration.sourcing.progress.cleanup.confirm.one': 'Êtes-vous sûr de vouloir supprimer les sourcings sans feedback du tenant {{tenant}} ? Un sourcing sera supprimé.',
        'administration.sourcing.progress.cleanup.confirm.one_plural': 'Êtes-vous sûr de vouloir supprimer les sourcings sans feedback du tenant {{tenant}} ? {{count}} sourcings seront supprimés.',
        'administration.sourcing.progress.cleanup.tooltip': 'Supprimer le sourcing sans feedback',
        'administration.sourcing.progress.cleanup.tooltip_plural': 'Supprimer les {{count}} sourcings sans feedback',
        'administration.sourcing.progress.details': 'Voir le détail',
        'administration.sourcing.progress.table.date': 'Date',
        'administration.sourcing.progress.table.tenant': 'Tenant',
        'administration.sourcing.progress.table.input': 'Feedbacks entrants',
        'administration.sourcing.progress.table.sourcing': 'Sourcing',
        'administration.sourcing.progress.table.processing': 'Processing',
        'administration.sourcing.progress.table.indexing': 'Indexing',
        'administration.sourcing.progress.table.indexed': 'Feedbacks indexés',
        'administration.sourcing.progress.table.errors': 'Erreurs',
        'administration.sourcing.progress.table.sourcingParsingDiscarded': 'Feedbacks évincés lors de l\'analyse des champs',
        'administration.sourcing.progress.table.sourcingRuleDiscarded': 'Feedbacks évincés lors des règles de champs',
        'administration.sourcing.progress.details.substream': 'ID',
        'administration.sourcing.progress.details.input.api': 'Feedbacks entrants par l\'API de sourcing',
        'administration.sourcing.progress.details.input.di': 'Feedbacks entrants par Vecko-Data-Integration',
        'administration.sourcing.progress.details.input.audio': 'Feedbacks Audio entrants',
        'administration.sourcing.progress.details.sourcing.transcribed': 'Transcription audio',
        'administration.sourcing.progress.details.sourcing.raw': 'Sourcing',
        'administration.sourcing.progress.details.pushed': 'Feedbacks poussés',
        'administration.sourcing.progress.details.retries': ' re-tentatives',
        'administration.sourcing.progress.details.acknowledged': ' acquittés',
        'administration.sourcing.progress.empty': 'Aucun',
        'administration.sourcing.logs': 'Logs',
        'administration.sourcing.logs.none': 'Aucune donnée',
        'administration.sourcing.logs.details': 'Voir le détail',
        'administration.sourcing.logs.details.stacktrace': 'StackTrace',
        'administration.sourcing.logs.table.date': 'Date',
        'administration.sourcing.logs.table.group': 'Groupe',
        'administration.sourcing.logs.table.tenant': 'Tenant',
        'administration.sourcing.logs.table.stream': 'Stream',
        'administration.sourcing.logs.table.substream': 'Substream',
        'administration.sourcing.logs.table.id': 'Id',
        'administration.sourcing.logs.table.message': 'Message',
        'administration.sourcing.logs.table.error': 'Erreur',
        'administration.sourcing.debug': 'Debug',
        'administration.sourcing.debug.tenant': 'Tenant:',
        'administration.sourcing.debug.substream': 'Substream: :',
        'administration.sourcing.debug.veckoId': 'Internal ID:',
        'administration.sourcing.errors': 'Erreurs',
        'administration.sourcing.errors.none': 'Aucune donnée',
        'administration.sourcing.errors.details': 'Voir le détail',
        'administration.sourcing.errors.debug': 'Open debug view',
        'administration.sourcing.errors.relaunch': 'Relaunch',
        'administration.sourcing.errors.details.stacktrace': 'StackTrace',
        'administration.sourcing.errors.table.date': 'Date',
        'administration.sourcing.errors.table.topic': 'Topic',
        'administration.sourcing.errors.table.system.tenantName': 'Tenant',
        'administration.sourcing.errors.table.system.stream': 'Stream',
        'administration.sourcing.errors.table.system.substream': 'Substream',
        'administration.sourcing.errors.table.system.id': 'Id',
        'administration.sourcing.errors.table.message': 'Message',
        'administration.sourcing.errors.table.error': 'Erreur',
        'administration.sourcing.feedback.relaunch.success': 'Process relancé avec succes',
        'administration.configuration': 'Configurations',
        'administration.configuration.git': 'Git',
        'administration.configuration.git.branch': 'Branche',
        'administration.configuration.git.push.success': 'Configuration poussée avec succès',
        'administration.configuration.git.error': 'Une erreur est survenue : {{error}}',
        'administration.configuration.validate': 'Valider',
        'administration.configuration.push': 'Pousser',
        'administration.configuration.validation.hint.level': 'Sévérité',
        'administration.configuration.validation.hint.message': 'Message',
        'administration.configuration.validation.valid': 'La configuration est valide',
        'administration.configuration.validation.invalid': 'La configuration n\'est pas valide',
        'administration.configuration.edit': 'Edition',
        'administration.configuration.edit.save.success': 'La configuration a été sauvegardé avec succès',
        'administration.export': 'Exports',
        'administration.export.export': 'Exports',
        'administration.export.fields': 'Champs',
        'administration.export.content': 'Contenu',
        'administration.export.output.xlsx': 'XLSX',
        'administration.export.output.csv': 'CSV',
        'administration.export.output.json': 'JSON',
        'administration.export.feedbacks.dataType.indexed': 'Indexés',
        'administration.export.feedbacks.dataType.analyzed': 'Analysés',
        'administration.export.feedbacks': 'Feedbacks',
        'administration.export.feedbacks.dataType': 'Source des feedbacks',


        'administration.export.feedbacks.filterType': 'Filtre',
        'administration.export.feedbacks.filterType.TAGS_BUT_NO_TOPIC': 'Feedbacks avec tag mais sans topic',
        'administration.export.feedbacks.filterType.NO_TOPIC': 'Feedbacks sans topic',
        'administration.export.feedbacks.filterType.NO_TAG': 'Feedbacks sans tag',
        'administration.export.feedbacks.filterType.WITH_TAG': 'Feedbacks avec tag',
        'administration.export.feedbacks.filterType.WITH_TOPIC': 'Feedbacks avec topic',
        'administration.export.feedbacks.filterType.ALL': 'Tous les feedbacks',

        'administration.export.output': 'Format de sortie',
        'administration.export.output.xlsx.description': '',
        'administration.export.output.json.description': '(Encodage: \'UTF-8\')',
        'administration.export.output.csv.description': '(Encodage: \'ISO_8859_1\'. Séparateur: \';\'. Guillemets: \'"\', Séparateur de ligne:\'\\n\\r\')',

        'administration.export.feedbacks.description.text': 'Données exportées.',
        'administration.export.feedbacks.description.fields': 'Tous les champs marqués comme exportable dans la configuration des champs (FIELDS).',
        'administration.export.feedbacks.indexed.description.content': 'La valeur de chaque champ est celle trouvée dans l\'index elasticsearch.',
        'administration.export.feedbacks.analyzed.description.content': 'La valeur de chaque champ est celle trouvée dans la table cassandra \'feedback_analyzed\'.',

        'administration.export.feedbacks.maxSize': 'Nombre maximum de feedbacks',

        'administration.export.highlight': 'Highlight',
        'administration.export.highlight.description.text': 'Exporte le détail d\'analyse des verbatims.',
        'administration.export.highlight.description.fields': 'Le fichier de sorti est constitué d\'un ensemble de page html (une par catégorie)',
        'administration.export.highlight.description.content': 'Chaque page html contient les phrases sur lesquelles la catégorie a été trouvé',


        'administration.di': 'Data integration',
        'administration.di.di.configuration': 'Configuration',
        'administration.di.configuration.description': 'Cette configuration permet de définir l\'utilisateur qui sera utilisé lors des appels API depuis vecko-di vers vecko-api. Cet utilisateur devra avoir le rôle \'c-vecko-di\'.',
        'administration.di.configuration.create': 'Vous pouvez créer l\'utilisateur grâce au lien ci-contre :',
        'administration.di.configuration.create.link': 'Créer un utilisateur',
        'administration.di.configuration.list': 'Vous pouvez lister les utilisateurs grâce au lien ci-contre :',
        'administration.di.configuration.list.link': 'Lister les utilisateurs',
        'administration.di.configuration.username': 'Nom d\'utilisateur',
        'administration.di.configuration.password': 'Mot de passe',
        'administration.di.configuration.save.success': 'La configuration a été sauvegardée avec succès.',
        'administration.di.di.apis': 'Apis',
        'administration.di.apis.add.dialog.title': 'Nouvelle api',
        'administration.di.apis.add.dialog.kind': 'Type',
        'administration.di.api.active': 'Active',
        'administration.di.api.duration': 'Nombre de jours à importer',
        'administration.di.api.interval.initial': 'Taille des intervals lors de l\'import initial',
        'administration.di.api.interval.stream': 'Taille des intervals lors du streaming habituel',
        'administration.di.api.interval.delay': 'Délai d\'attente avant le traitement d\'un interval',
        'administration.di.api.save.success': 'La configuration a été sauvegardée avec succès.',
        'administration.di.api.delete.success': 'La configuration a été supprimée avec succès.',
        'administration.di.api.intervals': 'Intervals',
        'administration.di.api.intervals.none': 'Aucun interval',
        'administration.di.api.intervals.status': 'État',
        'administration.di.api.intervals.interval': 'Interval',
        'administration.di.intervals.delete.success': 'Tous les intervals ont été supprimés avec succès.',

        'tenantSelector.current': 'Tenant courant ({{tenant}})',
        'tenantSelector.all': 'Tous les tenants',

        'feedbacksSelector.selected': 'Verbatims selectionnés',
        'feedbacksSelector.all': 'Verbatims disponibles',
        'feedbacksSelector.limitReached': 'Nombre limite de verbatims atteint.',


        'administration.sourcing.progress.reindexStreams': 'Reindexer les streams',
        'administration.sourcing.progress.reindexStreams.confirm.warn': '',
    }
};
