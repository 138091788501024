import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

interface CountWithUnitProps {
  i18nKey: string,
  count: number,
  countSpanStyle?: React.CSSProperties,
  unitSpanStyle?: React.CSSProperties
}

export const CountWithUnit: FunctionComponent<CountWithUnitProps> =
  ({ i18nKey, count, countSpanStyle, unitSpanStyle }) => {
    return <Trans i18nKey={i18nKey} count={count}>
      <span style={countSpanStyle}/>
      <span style={unitSpanStyle}/>
    </Trans>;
  };