import {Spinner, SpinnerSize} from '@blueprintjs/core';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {services} from '../../../../application/service/services';
import {SelectMode, Table, TableDataset, TableDatasetDisplay} from '../../../../component/table/Table';
import {VisualizationInstance} from '../../../model/viz/VisualizationInstance';
import {getDashboardResolvedParams} from '../../../state/selectors/dashboardSelector';
import './tableViz.scss';
import {ResolvedParams} from "../../../model/params/resolve/ResolvedParams";
import {ClickableViz} from "../ClickableViz";
import {FieldValue} from "../../../../application/model/field/FieldValue";
import {
    FieldVizFeedbacksQueryContext,
    MultiFieldsVizFeedbacksQueryContext,
    VizFeedbacksQueryContext
} from "../../../model/viz/VizFeedbacksQueryContext";
import {
    AggregationRenderer,
    horizontalBar,
    labelHeader,
    numberTrendRenderer,
    satScoreRenderer,
    volumeHeaderRenderer,
    volumeRenderer
} from "../../../../component/table/TableRenderers";
import {I18nLabel} from "../../../../application/service/i18nService";
import {Label} from "../../../model/model";
import {SortOrder, SortOrderEnum} from "../../../../utils/query/SortOrder";
import {variableResolverService} from "../../../service/variableResolverService";
import _ from "lodash";


interface Column {
    name: string,
    "@type": string,
    title?: Label,
    visible?: string
}

interface TableValue {
    name: string;
    value: any;
}

interface TableItem {
    data: TableValue[];
    children: Array<TableItem>
}

interface VisualizationUiResponse {
    totalCount: number;
    items: Array<TableItem>;
}

interface StateProps {
    clickItem?: any;
    inProgress: boolean,
    data?: any,
    sorting: { columnName: string, direction: SortOrder },

    top: { columnName: string, count: number, type: 'TOP' | 'FLOP' }
};

interface Column {
    compareFunction?: (columnName: string, direction: SortOrder) => (item1, itemB) => number;
    sortable: boolean;
    valueGetters?: { [key: string]: (item) => any }
}


interface TableVizProps extends WithTranslation {
    viz: VisualizationInstance,
    dashboardResolvedParams: ResolvedParams,
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}

const defaultCompareFunction = (valueGetter: (data: any) => any) => {
    return (columnName: string, direction: SortOrder) => {
        return (line1, line2) => {
            const line1Value = line1.data.filter(columnValue => columnValue.name === columnName).map(valueGetter).find(() => true);
            const line2Value = line2.data.filter(columnValue => columnValue.name === columnName).map(valueGetter).find(() => true);
            let result = 0;
            if(_.isNil(line1Value) && _.isNil(line2Value)) result = 0;
            if(_.isNil(line1Value)) result = -1;
            if(_.isNil(line2Value)) result = 1;
            if (!_.isNil(line1Value) && !_.isNil(line2Value)) {
                result = line1Value - line2Value;
            }
            return result * (direction == SortOrderEnum.ASC ? 1 : -1);
        }
    }
}

export class TableVizComponent extends React.PureComponent<TableVizProps> implements ClickableViz {

    columns: any = {};
    columnRenderers: { [key: string]: Partial<TableDatasetDisplay<any>> & Partial<Column> } = {
        ["opinions"]: {
            sortable: false,
            headerRenderer: labelHeader('dashboard.viz.table.opinions', false),
            dataRenderer: horizontalBar((columnValue, column) => {
                let result = [];
                if (columnValue && columnValue.value) {
                    const values = columnValue.value;
                    result = [
                        {value: values['POSITIVE'] ? values['POSITIVE'] : 0, color: 'green'},
                        {value: values['NEUTRAL'] ? values['NEUTRAL'] : 0, color: 'grey'},
                        {value: values['NEGATIVE'] ? values['NEGATIVE'] : 0, color: 'red'}];

                }
                return result;
            }),
            valueGetters: {
                "positive": (item) => item.value.POSITIVE,
                "negative": (item) => item.value.NEGATIVE
            }
        },
        ["distribution"]: {
            sortable: false,
            headerRenderer: labelHeader('dashboard.viz.table.distribution', false),
            dataRenderer: horizontalBar((columnValue, column) => {
                const distributionResult = [];
                if (columnValue && columnValue.value) {
                    const values = columnValue.value;

                    const columnDef = this.props.viz.definition.params.columns.find(currentColumn => currentColumn.name == column.columnName)
                    for (const property in values) {
                        let color = null;
                        if (columnDef) {
                            if (columnDef.values) {
                                const columnDefValue = Object.entries(columnDef.values)
                                    .find(([columnDefStringValue, columnDefProps]) => columnDefStringValue === property)
                                color = columnDefValue ? columnDefValue[1]['color'] : null;
                            }
                        }
                        if (!color) {
                            color = services.getColorService().getColorForFieldValue(columnDef.field, property);
                        }

                        const val = {value: values[property], color: color};
                        distributionResult.push(val);
                    }
                }
                return distributionResult;
            })
        },
        ["volume"]: {
            sortable: true,
            headerRenderer: volumeHeaderRenderer(),
            dataRenderer: volumeRenderer,
            compareFunction: defaultCompareFunction((item) => {
                return item.value.count;
            })
        },
        ["volume_trend"]: {
            sortable: true,
            headerRenderer: labelHeader('dashboard.viz.table.trend', true),
            dataRenderer: numberTrendRenderer,
            compareFunction: defaultCompareFunction((item) => {
                return item.value;
            })
        },
        ["satScore"]: {
            sortable: true,
            headerRenderer: labelHeader('dashboard.viz.table.satScore', true),
            dataRenderer: satScoreRenderer,
            compareFunction: defaultCompareFunction((item) => {
                return item.value.sat;
            })
        },
        ["tonalities"]: {
            sortable: false,
            headerRenderer: labelHeader('dashboard.viz.table.tonalities', false),
            dataRenderer: horizontalBar((columnValue, column) => {
                const distributionResult = [];
                if (columnValue && columnValue.value) {
                    const values = columnValue.value;

                    const columnDef = this.props.viz.definition.params.columns.find(currentColumn => currentColumn.name == column.columnName)
                    for (const property in values) {
                        let color = null;
                        if (columnDef) {
                            if (columnDef.values) {
                                const columnDefValue = Object.entries(columnDef.values)
                                    .find(([columnDefStringValue, columnDefProps]) => columnDefStringValue === property)
                                color = columnDefValue ? columnDefValue[1]['color'] : null;
                            }
                        }
                        if (!color) {
                            color = services.getColorService().getColorForFieldValue(columnDef.field, property);
                        }

                        const val = {value: values[property], color: color};
                        distributionResult.push(val);
                    }
                }
                return distributionResult;
            }),
            valueGetters: {
                "positive": (item) => item.value.positive,
                "negative": (item) => item.value.negative,
                "neutral": (item) => item.value.neutral
            }
        }
    };


    private getTopXLeaves(tree: Array<TableItem>, top: number, compareFunctions: ((itemA, itemB) => number)[]): TableItem[] {
        const leaves: TableItem[] = this.getLeaves(tree);
        const compareFunction = this.wrappeComparator(compareFunctions);
        // Tri des feuilles en fonction de la valeur de "count"
        const sortedLeaves = leaves.sort(compareFunction);
        // Récupération des trois premières feuilles
        const topXLeaves = sortedLeaves.slice(0, top);
        return this.getTreeFromLeaves(tree, topXLeaves);

    }

    private wrappeComparator(compareFunctions: ((itemA, itemB) => number)[]) {
        let compareFunction = compareFunctions.shift();
        if (compareFunctions.length > 0) {
            const nextLevelComparator = this.wrappeComparator(compareFunctions)
            return (itemA, itemB) => {
                let comparatorResult = compareFunction(itemA, itemB);
                if (comparatorResult == 0) {
                    comparatorResult = nextLevelComparator(itemA, itemB);
                }
                return comparatorResult;
            }
        }
        return compareFunction;
    }

    private getTreeFromLeaves(tree: Array<TableItem>, topXLeaves: TableItem[]): Array<TableItem> {
        const newTree: Array<TableItem> = [];
        tree.forEach(item => {
            if (item.children && item.children.length > 0) {
                const childTree = this.getTreeFromLeaves(item.children, topXLeaves);
                if (childTree && childTree.length > 0) {
                    newTree.push({...item, children: childTree});
                }
            } else if (topXLeaves.indexOf(item) >= 0) {
                newTree.push(item);
            }
        })
        return newTree;
    }

    getLeaves(tree: Array<TableItem>): TableItem[] {
        const leaves: TableItem[] = [];
        tree.forEach(value => {
            if (!value.children || value.children.length === 0) {
                // Si le nœud n'a pas d'enfants, il est une feuille
                leaves.push(value);
                return;
            }
            // Récupération des feuilles de chaque enfant récursivement
            this.getLeaves(value.children).forEach(childItem => leaves.push(childItem));
        });
        return leaves;
    }


    state: StateProps = {
        inProgress: false,
        sorting: null,
        top: null
    };

    static getDerivedStateFromProps = (props, state: StateProps) => {
        const {data} = props;
        return {...state, data: data};
    };

    isExpanded(expandedValues: Array<any>, rowValue: any): boolean {
        return expandedValues.find(value => value.field === rowValue.field && value.fieldValue === rowValue.fieldValue) != null;
    }

    onSelectionChange(selection: Array<any>, newlySelectedItem: any, event: MouseEvent) {
        this.state.clickItem = newlySelectedItem;
        const {t, viz} = this.props;
        const field = services.getFieldsService().getField(newlySelectedItem.field);
        const fieldValue = services.getFieldsService().findFieldValue(field.name, newlySelectedItem.fieldValue);
        this.props.openFeedbacksPanel(this, fieldValue.getLabel(t));
        event.preventDefault();
        event.stopPropagation();
    }

    setData(data) {
        this.setState({inProgress: false, data: data});
    }

    containsLevels() {
        return this.state.data.items.filter(item => item.children && item.children.length > 0).length > 0;
    }

    onSort(columnName: string, direction: SortOrder) {
        this.setState({...this.state, sorting: {columnName, direction}});
    }

    getColumns(): Array<TableDatasetDisplay<any>> {
        const {viz, t} = this.props;
        const params = viz.definition.params;

        const aggregationColumnsToDisplay = [];

        const columns = this.props.viz.definition.params.columns as Array<Column>;

        columns.filter(value => value["@type"] === "aggregation").forEach(aggregation => {
            const field = services.getFieldsService().getField(aggregation['field']);
            aggregationColumnsToDisplay.push({
                columnName: aggregation['field'],
                style: aggregation['style'] ? aggregation['style'] : {flex: 2, textAlign: 'left'},
                headerRenderer: labelHeader(field ? field.getLabel(t) : aggregation['field'], true),
                dataRenderer: AggregationRenderer,
                compareFunction: (columnName: string, direction: SortOrder) => {
                    return (line1, line2) => {
                        if (line1.field !== columnName) return 0;
                        const field = services.getFieldsService().getField(line1.field);
                        const fieldValues = services.getFieldsService().getFieldValues(field.name);

                        const line1Value = fieldValues && fieldValues.find(line1.fieldValue) ? fieldValues.find(line1.fieldValue).getLabel(t) : line1.fieldValue;
                        const line2Value = fieldValues && fieldValues.find(line2.fieldValue) ? fieldValues.find(line2.fieldValue).getLabel(t) : line2.fieldValue;
                        let result = 0;
                        if (line1Value < line2Value) {
                            result = -1;
                        }
                        if (line1Value > line2Value) {
                            result = 1;
                        }
                        return result * (direction == SortOrderEnum.ASC ? 1 : -1);
                    }
                }
            })
        });

        const columnsToDisplay = columns.filter(value => value["@type"] !== "aggregation")
            .map(col => {
                if (col.visible) {
                    const visibleAsString = variableResolverService.resolveParamInString(col.visible, this.props.dashboardResolvedParams.valueAsPlainObject());
                    const visible = visibleAsString ? eval(visibleAsString) : true;
                    if (!visible) return null;
                }

                const colDef = {...this.columnRenderers[col['@type']], columnName: col.name, ...col};
                if (col.title) {
                    const titleKey = col.title?.key ? col.title.key : getColumnLabelKey(this.props.viz.dashboardId, this.props.viz.definition, col.name)
                    const headerRender = labelHeader(titleKey, colDef.sortable, col.title);
                    colDef.headerRenderer = headerRender;
                }
                if (!colDef) throw new Error("Not column definition found for : " + col.name)
                return colDef as TableDatasetDisplay<any>;
            }).filter(value => !!value);

        return (aggregationColumnsToDisplay || []).concat(columnsToDisplay);
    }

    getUiDataset(): TableDataset<any> {
        const {viz, t} = this.props;
        const params = viz.definition.params;

        const cols = this.getColumns();
        cols.forEach(col => this.columns[col.columnName] = col);
        let itemsToDisplay = viz.data.items;
        if (viz.definition.params.top) {
            const top = viz.definition.params.top;
            const compareFunctions = [];
            (top.columns as (string | Partial<{ name: string, sortOn?: string }>)[]).forEach(column => {
                let compareFunction = null;
                let columnName: string = null;
                if (typeof column === 'string') {
                    compareFunction = this.columns[column].compareFunction;
                    columnName = column;
                } else {
                    columnName = column.name;
                    if (this.columns[column.name].compareFunction) {
                        compareFunction = this.columns[column.name].compareFunction;
                    } else if (column.sortOn) {
                        if (!this.columns[column.name].valueGetters || !this.columns[column.name].valueGetters[column.sortOn]) {
                            throw new Error("Not value getter found for column " + column.name);
                        }
                        compareFunction = defaultCompareFunction(this.columns[column.name].valueGetters[column.sortOn]);
                    }
                }
                compareFunctions.push(compareFunction(columnName, top.type == 'TOP' ? SortOrderEnum.DESC : SortOrderEnum.ASC));
            })
            itemsToDisplay = this.getTopXLeaves(itemsToDisplay, top.count, compareFunctions);
        }

        if (this.state.sorting) {
            itemsToDisplay = this.sort(itemsToDisplay);
        }

        return {
            data: itemsToDisplay,
            display: cols
        };
    }

    private sort(items: Array<any>) {
        const {viz, dashboardResolvedParams} = this.props;
        const column = this.columns[this.state.sorting.columnName];
        const compareFunction = column.compareFunction(this.state.sorting.columnName, this.state.sorting.direction);
        const sortedItems = [...items].sort(compareFunction).map(item => {
            return {...item}
        });
        sortedItems.forEach(item => {
            if (item.children) {
                item.children = this.sort(item.children);
            }
        })
        return sortedItems;
    }

    renderTable(uiParams) {
        return <Table style={{width: "100%"}} color={uiParams.color}
                      expandable={this.containsLevels()}
                      separatorColor={uiParams.color}
                      dataset={this.getUiDataset()}
                      selectMode={SelectMode.ROW}
                      selectionChanged={this.onSelectionChange.bind(this)}
                      cellCanSelect={true}
                      onSort={this.onSort.bind(this)}
                      selectable={true}
                      isExpandedFunction={this.isExpanded}
                      highlight={
                          {
                              highlightedClassName: 'highlightedCell',
                              isHighlighted: (rowValue, rowIndex) => rowValue.highlighted
                          }
                      }
        />;
    }

    render() {
        const {t, viz} = this.props;
        const {inProgress} = this.state;
        const uiParams = viz.definition.uiParams;

        return <>
            <div className="tableViz">
                <div className="tableViz-main">
                    {
                        inProgress ? <Spinner size={SpinnerSize.SMALL}/> : this.renderTable(uiParams)
                    }
                </div>
            </div>
        </>;
    }

    getClickedFieldValue(): FieldValue {
        if (this.state.clickItem) {
            const {viz} = this.props;
            return services.getFieldsService().findFieldValue(viz.definition.params.field, this.state.clickItem.category);
        }
        return null;
    }

    getFeedBacksQueryContext(): VizFeedbacksQueryContext {
        const result = new MultiFieldsVizFeedbacksQueryContext();
        if (this.state.clickItem) {
            const {viz} = this.props;
            result.fields = this.getFieldsFromItem(this.state.clickItem);
        }
        return result;
    }

    private getFieldsFromItem(clickItem: any): Array<FieldVizFeedbacksQueryContext> {
        const result = [];
        if (clickItem.parent) {
            result.push(...this.getFieldsFromItem(clickItem.parent));
        }
        const field = new FieldVizFeedbacksQueryContext();
        field.field = clickItem.field;
        field.selectedValue = clickItem.fieldValue;
        result.push(field);
        return result;
    }


}

const mapStateToProps = (state) => {
    return {
        dashboardResolvedParams: getDashboardResolvedParams(state),
        resolvedSpec: state.dashboard.resolvedSpec
    };
};


const mapDispatchToProps = (dispatch, props) => {
    return {
        openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
            dispatch.dashboard.openFeedBackPanel({viz, panelTitle});
        },
    }
};
export const TableViz = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withTranslation(undefined, {withRef: true})(TableVizComponent));

function getColumnLabelKey(reportId: string, vizDefinition: any, columnName: string) {
    return `report.${reportId}.viz.${vizDefinition.id}.column.${columnName}.title`;
}

TableViz['getLabels'] = (report: any, vizDefinition: any): Array<I18nLabel> => {
    const labels: Array<I18nLabel> = [];
    vizDefinition.params.columns.forEach(column => {
        if (column.title && column.title.labels) {
            Object.entries(column.title.labels)
                .forEach(([lang, label]) => labels.push({
                    key: getColumnLabelKey(report.id, vizDefinition, column.name),
                    value: label as string,
                    lang: lang
                }))
        }
    })
    return labels;
}