import {TFunction} from "i18next";
import {ParamValue} from "./values/ParamValue";
import {Label} from "../model";


export abstract class Param<T extends ParamValue = ParamValue> {
    static applyCommonsDefinition<T extends Param<any>>(target: T, object: Dict<any>): T {
        target.optional = object.optional || false;
        target.displayInPDF = object.displayInPDF || false;
        target.title = object.title || null;
        return target;
    }

    readonly type: string;
    readonly name: string;
    readonly reportId: string;
    optional: boolean = false;
    displayInPDF: boolean = false;
    title: Label;

    protected constructor(reportId: string, type: string, name: string) {
        this.type = type;
        this.name = name;
        this.reportId = reportId;
    }

    getPlaceholder(t: TFunction): string {
        return this.name;
    }

    protected getValue(state: Dict<OneOrMany<ParamValue>>): Maybe<OneOrMany<T>> {
        return state[this.name] as OneOrMany<T>;
    }

    getTitle(t: TFunction): string {
        if (this.title) {
            return t(this.title?.key ? this.title.key : `report.${this.reportId}.param.${this.name}.title`, this.title as any) as string;
        }
        return null;
    }


    abstract resolveValue(state: Dict<OneOrMany<ParamValue>>, init: boolean): Maybe<OneOrMany<ParamValue>>;

    abstract valueAsPlainObject(value: OneOrMany<T>): any;
}