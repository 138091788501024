import {SortOrder} from "../../utils/query/SortOrder";
import React, {FunctionComponent, MouseEventHandler} from "react";
import {SeparatedComponents} from "../SeparatedComponents";
import {Icon} from "@blueprintjs/core";
import "./orderHeader.scss";
import {IconName} from "@blueprintjs/icons";

interface OrderHeaderProps {
    label: string,
    order?: SortOrder,
    onClick?: MouseEventHandler
}

export const OrderHeader: FunctionComponent<OrderHeaderProps> = ({label, order, onClick}) => {
    const icon:string  = order ? order.icon : 'blank';
    const localOnclick = onClick? onClick : ()=>{};
    return <div className='orderHeader' onClick={localOnclick}>
        <SeparatedComponents>
            {label}
            {order && onClick ? <Icon className='topicsStatTable-orderHeader-icon' icon={icon as IconName}/> : null}
        </SeparatedComponents>
    </div>;
};