import {TopicStat} from "../../application/model/TopicStat";
import {SmartIcon} from "../icon/SmartIcon";
import {Cell} from "./Cell";
import React, {PureComponent, ReactElement} from "react";
import {Colors} from "@blueprintjs/core";
import _ from "lodash";
import {OrderHeader} from "./OrderHeader";
import {WithTranslation, withTranslation} from "react-i18next";
import {DistributionItem} from "../../application/model/distribution/DistributionItem";
import {SortOrder, SortOrderEnum} from "../../utils/query/SortOrder";
import {TableDatasetDisplay} from "./Table";
import {services} from "../../application/service/services";
import {StackedBar} from "../progressBar/StackedBar";
import {Metric} from "../../report/model/MetricEnum";
import {SatTypeEnum} from "../../application/model/SatTypeEnum";
import {TFunction} from "i18next";

export type ValueRendererProps<T> = {
    rowValue: T;
    display: TableDatasetDisplay<T>
};

export const indexRenderer = (rowValue) => {
    return rowValue.rank;
};

export interface ValueToDisplay {
    metric: Metric,
    value: any
}

interface MultiMetricCellProps {
    metricsToDisplay: Array<Metric>;
    values: any;
    renderMetricValue: (m: Metric, v: ValueToDisplay) => ReactElement;
}

export class MultiMetricCell extends React.PureComponent<MultiMetricCellProps> {

    render() {
        const {metricsToDisplay, values, renderMetricValue} = this.props;

        const valuesToDisplay: ValueToDisplay[] = metricsToDisplay
            .map(m => ({metric: m, value: values[m.name]}))
            .filter(o => !_.isNil(o.value));

        if (valuesToDisplay.length === 0) {
            return null;
        }
        if (valuesToDisplay.length === 1) {
            return renderMetricValue(valuesToDisplay[0].metric, valuesToDisplay[0].value);
        }

        return <div className="multi-metric-cell">
            <div>
                {
                    valuesToDisplay.map(o => <div key={o.metric.name}>{renderMetricValue(o.metric, o.value)}</div>)
                }
            </div>
        </div>;
    }
}

export type ValueRendererPropsWithTranslation<T> = ValueRendererProps<T> & WithTranslation;

export class ValueRenderer<T, P extends ValueRendererProps<T>> extends PureComponent<ValueRendererProps<T>> {
    public isComponent() {
        return true;
    }
}

type TopicRendererProps<T> = {} & ValueRendererPropsWithTranslation<T>

class TopicRendererComp extends ValueRenderer<TopicStat, TopicRendererProps<TopicStat>> {
    getProps() {
        return this.props as TopicRendererProps<TopicStat>
    }

    render() {
        const {rowValue, t} = this.getProps();

        return (
            <div className="topicsStatTable-topic">
                {rowValue.hasChildren() ? null : <SmartIcon icon='dot'/>}
                <span className="topicsStatTable-topicLabel">{rowValue.topic.getLabel(t)}</span>
            </div>
        );
    }
}

export const TopicRenderer = withTranslation()(TopicRendererComp);

type AggregationRendererProps<T> = {} & ValueRendererPropsWithTranslation<T>

class AggregationRendererComp extends ValueRenderer<any, AggregationRendererProps<any>> {
    getProps() {
        return this.props as AggregationRendererProps<any>
    }

    render() {
        const {rowValue, t, display} = this.getProps();
        if (display.columnName != rowValue.field) {
            return <div style={{minWidth: 110, maxWidth: 110, display: 'inline-block', textAlign: 'left'}}>
            </div>
        }
        const field = services.getFieldsService().getField(rowValue.field);
        const fieldValues = services.getFieldsService().getFieldValues(field.name);

        const columnValue = fieldValues && fieldValues.find(rowValue.fieldValue) ? fieldValues.find(rowValue.fieldValue).getLabel(t) : rowValue.fieldValue;
        return (
            <div style={{minWidth: 110, maxWidth: 110, display: 'inline-block', textAlign: 'left'}}>
                <span>{columnValue}</span>
            </div>
        );
    }
}

export const AggregationRenderer = withTranslation()(AggregationRendererComp);

export function horizontalBar(valueProvider: (value, column: TableDatasetDisplay<any>) => any) {
    return (rowValue, column: TableDatasetDisplay<any>) => {
        const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
        return <StackedBar values={valueProvider(columnValue, column)}></StackedBar>;
    }
}

export function distributionRendererWithValueProvider(valueProvider: DistributionItem) {

}

export function numberRenderer(rowValue, column: TableDatasetDisplay<any>) {
    const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
    return <Cell count={columnValue.value} color={Colors.GRAY1}/>;
}

export function numberTrendRenderer(rowValue, column: TableDatasetDisplay<any>) {
    const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
    let displayValue = columnValue.value;
    if (columnValue.value === 0) {
        displayValue = "=";
    } else if (columnValue.value > 0) {
        displayValue = "+" + columnValue.value;
    }
    return <div style={{
        minWidth: 110,
        maxWidth: 110,
        color: column.style?.color ? column.style.color : null,
        display: 'inline-block',
        textAlign: column.style?.textAlign ? column.style.textAlign : "center"
    }}>
        <span>{displayValue}</span>
    </div>;
}

export function stringRenderer(rowValue, column: TableDatasetDisplay<any>) {
    const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
    return <div style={{minWidth: 110, maxWidth: 110, display: 'inline-block', textAlign: 'left'}}>
        <span>{columnValue}</span>
    </div>;
}

export function satScoreRenderer(rowValue, column: TableDatasetDisplay<any>) {
    const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
    const satType = SatTypeEnum.valueOf((column as any).satType);
    if (!columnValue) return null;
    if (columnValue['display'] == 'distribution') {
        return satDistributionRenderer(rowValue, column);
    }
    return <Cell count={columnValue.value.sat} nbDecimal={satType.nbOfDecimal} color={Colors.GRAY1}
                 textAlign={'center'}/>;
}

export function satDistributionRenderer(rowValue, column: TableDatasetDisplay<any>) {
    const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
    const satType = SatTypeEnum.valueOf((column as any).satType);
    return <StackedBar values={rowValue}></StackedBar>;
}


export function volumeRenderer(rowValue, column: TableDatasetDisplay<any>) {
    const columnValue = rowValue.data.filter(columnValue => columnValue.name === column.columnName).find(() => true);
    return <Cell count={columnValue.value.count} percent={columnValue.value.percent} color={Colors.GRAY1}/>;
}

export function distributionRenderer(distributionItem: DistributionItem) {
    return (rowValue) => {
        const value = rowValue.distribution[distributionItem.id];
        return (<Cell count={value.count} percent={value.percent} color={distributionItem.color}/>);
    };
}


export type LabelFunction = (t: TFunction) => string;

/// headers
export type HeaderRendererProps = {
    columnIndex: number,
    label: string | LabelFunction,
    params?: { [key: string]: any },
    columnName: string,
    order: SortOrder,

    sortable?: boolean,
    sortHandler: (columnName: string, columnIndex: number, sortDirection: SortOrder, event?) => void
}

export type HeaderRendererPropsWithTranslation = HeaderRendererProps & WithTranslation;

export class AbstractHeaderRenderer<P extends HeaderRendererProps> extends PureComponent<HeaderRendererProps> {
    public isComponent() {
        return true;
    }
}

export class HeaderRendererComp extends AbstractHeaderRenderer<HeaderRendererPropsWithTranslation> {
    getProps(): HeaderRendererPropsWithTranslation {
        return this.props as HeaderRendererPropsWithTranslation
    }

    onSort(event) {
        if (this.props.sortHandler) {
            this.props.sortHandler(this.props.columnName, this.props.columnIndex, this.toggleDirection(), event);
        }
    }

    render() {
        const label = typeof this.getProps().label === 'string' ?
            this.getProps().t(this.getProps().label as string, this.getProps().params) as string :
            ((this.getProps().label) as LabelFunction)(this.getProps().t);

        return !this.getProps().sortable ?
            <OrderHeader label={label}></OrderHeader> : <OrderHeader label={label}
                                                                     order={this.props.order}
                                                                     onClick={this.onSort.bind(this)}></OrderHeader>;
    }

    toggleDirection(): SortOrder {
        return this.props.order === SortOrderEnum.ASC ? SortOrderEnum.DESC : SortOrderEnum.ASC;
    }
}

export const HeaderRenderer = withTranslation()(HeaderRendererComp)

export const volumeHeaderRenderer = () => {
    return labelHeader('topics.table.volume', true);
}

export const labelHeader = (labelOrKey, sortable: boolean, i18nParams?) => {
    return (column: TableDatasetDisplay<any>, columnIndex, currentOrder, sorthandler) => {
        return <HeaderRenderer sortable={sortable} columnIndex={columnIndex} label={labelOrKey} params={i18nParams}
                               columnName={column.columnName}
                               order={currentOrder}
                               sortHandler={sorthandler}></HeaderRenderer>;
    }
}

export const distributionHeaderRenderer = (distributionItem: DistributionItem) => {
    return (column: TableDatasetDisplay<any>, columnIndex, currentOrder, sorthandler) => {
        return <HeaderRenderer sortable={false} columnIndex={columnIndex}
                               label={(t) => _.capitalize(distributionItem.getLabel(t))}
                               columnName={distributionItem.id}
                               order={currentOrder} sortHandler={sorthandler}></HeaderRenderer>;
    }
}

