export default {
    translations: {
        'common.yes': 'Yes',
        'common.no': 'No',
        'application.copyright': '© Vecko 2021',

        'globalSatisfaction.POSITIVE': 'Positive',
        'globalSatisfaction.NEUTRAL': 'Neutral',
        'globalSatisfaction.NEGATIVE': 'Negative',
        'globalSatisfaction.MIXED': 'Mixed',

        'system.tenant': 'Tenant',
        'system.substream': 'Substream',

        'channelKind': 'Channel',
        'channelKind.CHAT': 'Chat',
        'channelKind.EMAIL': 'E-mail',
        'channelKind.SURVEY': 'Survey',
        'channelKind.FACEBOOK': 'Facebook',
        'channelKind.PHONE': 'Phone',
        'channelKind.WEBMAIL': 'Form',
        'channelKind.invalid': 'Invalid {{channelKindFieldLabel}} : {{channelKind}}',
        'channelKind.empty': '{{channelKindFieldLabel}} not set',
        'noResult': 'No result',
        'customerJourney': 'Customer journey',
        'topic': 'Topic',
        'topics': 'Topics',
        'filter': 'Filter',
        'filter.tag.notEmptyVerbatim': 'Exclude empty verbatims',
        'dateRange.thisWeek': 'This week',
        'dateRange.lastWeek': 'Last week',
        'dateRange.thisMonth': 'This month',
        'dateRange.lastMonth': 'Last month',
        'dateRange.thisQuarter': 'This quarter',
        'dateRange.lastQuarter': 'Last quarter',
        'dateRange.thisYear': 'This year',
        'dateRange.lastYear': 'Last year',
        'dateRange.today': 'Today',

        'chronoUnit.MILLIS': 'Milliseconds',
        'chronoUnit.SECONDS': 'Seconds',
        'chronoUnit.MINUTES': 'Minutes',
        'chronoUnit.HOURS': 'Hours',
        'chronoUnit.DAYS': 'Days',

        'filter.title': 'Filters',
        'filter.clearAll': 'Clear all filters',
        'filter.category.value.none': 'Not categorized',
        'filter.field.value.none': 'None',

        'explore.title': 'Analysis',

        'topics.title': 'Customer journey',
        'topics.table.topic': 'Topic',
        'topics.table.volume': 'Volume',
        'topics.all': 'All topics',
        'misc.feedback.count': '<0>{{count}}</0> <1>$t(misc.feedback)</1>',
        'misc.opinion.count': '<0>{{count}}</0> <1>$t(misc.opinion)</1>',

        'distributionKind': 'Distribution',
        'distributionKind.tonalities': 'Opinions tonality',
        'distributionKind.tonalities.item': '$t(common.Tonality.{{item}}_plural) $t(misc.opinion_plural)',
        'distributionKind.satType': '$t(satType.{{satType}})',
        'distributionKind.satType.item': '$t(satType.{{satType}}.{{item}}_plural)',

        'analysis.title': 'Deep analysis',
        'analysis.goTo': 'Go to deep analysis',

        'feedbacks.conversations': '{{count}} $t(misc.conversation)',
        'feedbacks.export.tooltip': 'Export the 500 first $t(misc.feedback_plural)',
        'feedbacks.delete.button': 'Delete matching feedbacks',
        'feedbacks.delete.request.success': 'Delete matching feedbacks request sent successfully.',
        'feedbacks.delete.confirm.title': '$t(administration.sourcing.progress.dangerousActionWarning): All data will be deleted!',
        'feedbacks.delete.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning): All corresponding feedback to search will be deleted!',
        'feedbacks.export.dialog.title': 'Feedbacks export',
        'feedbacks.export.dialog.outputType': 'Output format',
        'feedbacks.export.dialog.maxSize': 'Maximum number of feedbacks',
        'feedbacks.export.dialog.fields.system': 'System fields',
        'feedbacks.export.dialog.fields.configFields': 'Configuration fields',
        'administration.export.feedbacks.fields': 'Fields to export',

        'kpi.countByCategories.label': 'percentage of $t(misc.conversation_plural)',
        'kpi.countByCategories.number': '{{count}}',
        'kpi.countByCategories.legend': '$t(misc.conversation) ({{percent}} %)',
        'kpi.countByTonalityAndCategories.label': '$t(common.Tonality.{{tonality}}_plural) $t(misc.opinion_plural)',
        'kpi.countByTonalityAndCategories.number': '{{count}} %',
        'kpi.countByTonalityAndCategories.legend': 'of $t(common.Tonality.{{tonality}}_plural) $t(misc.opinion_plural)',
        'kpi.satImpactByCategories.label': 'impact on the $t(satType.{{satType}}.short)',
        'kpi.satImpactByCategories.number': '$t(misc.signedValue) $t(misc.unit.point)',
        'kpi.satImpactByCategories.legend': 'sum of the impact on the $t(satType.{{satType}}.short)',
        'kpi.countBySatPopulationAndCategories.label': '$t(satType.{{satType}}.{{satMood}}_plural)',
        'kpi.countBySatPopulationAndCategories.number': '{{count}} %',
        'kpi.countBySatPopulationAndCategories.legend': 'of $t(satType.{{satType}}.{{satMood}}_plural)',
        'kpi.satByCategories.label': '$t(satType.{{satType}})',
        'kpi.satByCategories.number': '{{count}}',
        'kpi.satByCategories.legend': '$t(satType.{{satType}})',

        'misc.feedback': 'conversation',
        'misc.feedback_plural': 'conversations',
        'misc.opinion': 'opinion',
        'misc.opinion_plural': 'opinions',
        'misc.conversation': 'conversation',
        'misc.conversation_plural': 'conversations',
        'misc.signedValue_negative': '{{count}}',
        'misc.signedValue_positive': '+{{count}}',
        'misc.unit.point': 'point',
        'misc.unit.point_plural': 'points',
        'misc.percent': '<0>{{count}}</0> <1>%</1>',

        'misc.search.pagination.currentPage': 'page {{current}}/{{pagesCount}}',
        'misc.search.noResult': 'No result',


        'dashboard': 'dashboard',
        'dashboard_plural': 'dashboards',
        'my_dashboards': 'My Dashboards',

        'report': 'report',
        'report_plural': 'reports',

        'dashboard.viz.topicImpact.title': "Impact of <0>{{polarity}}</0> topics on $t(satType.{{satType}}.short)",
        'dashboard.viz.topicImpact.header.topic': "$t(dashboard.misc.topic_plural)",
        'dashboard.viz.topicImpact.header.impact': "impact on $t(satType.{{satType}}.short)",
        'dashboard.viz.topicImpact.header.count': "conversations",

        'dashboard.viz.satScore.title': "$t(satType.{{satType}}) ({{subtitle}})",
        'dashboard.viz.satScore.title_noSubtitle': "$t(satType.{{satType}})",
        'dashboard.viz.satScore.count': "participants",

        'dashboard.viz.satEvolution.title': '$t(satType.{{satType}})',
        'dashboard.viz.satEvolution.serie.global': 'Global $t(satType.{{satType}}.short)',

        'dashboard.viz.volume.title': "Volume ({{subtitle}})",
        'dashboard.viz.volume.title_noSubtitle': "Volume",
        'dashboard.viz.volume.unit': "conversation",
        'dashboard.viz.volume.unit_plural': "conversations",

        'dashboard.viz.metric.title': '{{title}} ({{subtitle}})',
        'dashboard.viz.metric.title_noSubtitle': "{{title}}",
        'dashboard.viz.metric.editor.main': 'Main value',
        'dashboard.viz.metric.editor.trend': 'Trend',
        'dashboard.viz.metric.editor.secondary': 'Secondary value',
        'dashboard.viz.metric.editor.percentageMode': 'Percentage mode',
        'dashboard.viz.metric.editor.showTrend': 'Display trend',
        'dashboard.viz.metric.editor.showSecondary': 'Display secondary value',
        'dashboard.viz.metric.editor.valueUnit': 'Unit',
        'dashboard.viz.metric.editor.valueUnit.override': 'Custom value',
        'dashboard.viz.metric.editor.valueUnit.helperText': 'Default value is :',
        'dashboard.viz.metric.editor.font': 'Font',
        'dashboard.viz.metric.editor.font.size': 'Size (px)',
        'dashboard.viz.metric.editor.font.color': 'Color',

        'dashboard.viz.volumeEvolution.title': 'Volume',
        'dashboard.viz.volumeEvolution.serie.global': 'Global',

        'dashboard.viz.actionPlan.title': 'Improvement means',
        'dashboard.viz.actionPlan.overall': 'Overall {{satType}} ({{value}})',
        'dashboard.viz.actionPlan.axis.satisfaction': 'Satisfaction',
        'dashboard.viz.actionPlan.axis.frequency': 'Importance',
        'dashboard.viz.actionPlan.area.watch': 'Watch',
        'dashboard.viz.actionPlan.area.generalize': 'Generalize',
        'dashboard.viz.actionPlan.area.enhance': 'Enhance',
        'dashboard.viz.actionPlan.area.capitalize': 'Capitalize',
        'dashboard.viz.actionPlan.editor.header.topic': '$t(dashboard.misc.topic_plural)',
        'dashboard.viz.actionPlan.editor.header.satisfaction': '$t(dashboard.viz.actionPlan.axis.satisfaction)',
        'dashboard.viz.actionPlan.editor.header.importance': '$t(dashboard.viz.actionPlan.axis.importance)',
        'dashboard.viz.actionPlan.editor.automaticSelection': 'Automatic selection',

        'dashboard.viz.topicImpactTopEvolution.title': "Biggest <0>$t(common.Tonality.{{tonality}})</0> impact on $t(satType.{{satType}}.short)",
        'dashboard.viz.topicImpactTopEvolution.contrib': "{{contrib}} points",
        'dashboard.viz.topicImpactTopEvolution.editor.title': '<0>$t(common.Tonality.{{tonality}}_plural)</0> customer comments',

        'dashboard.viz.actionList.title': 'Action plan',
        'dashboard.viz.actionList.emptyLabel': 'Update the <strong>Action plan</strong>',
        'dashboard.viz.actionList.editor.actionListNumber': 'Action plan n°{{index}}',

        'dashboard.viz.customerToRecall.title': 'Customers requiring a call back',

        'dashboard.viz.ranking.title': '$t({{field}}) ranking',
        'dashboard.viz.ranking.header.value': 'score /{{unit}}',
        'dashboard.viz.ranking.header.count': 'conversations',
        'dashboard.viz.ranking.header.percentage': 'Percentage',
        'dashboard.viz.ranking.header.opinions': '$t(misc.opinion_plural)',
        'dashboard.viz.ranking.editor.metricRanges': 'Thresholds',
        'dashboard.viz.ranking.editor.exclusions': 'Exclusions',
        'dashboard.viz.ranking.editor.inclusions': 'Inclusions',
        'dashboard.viz.ranking.editor.sort': 'Sort',
        'dashboard.viz.ranking.editor.display': 'Display',

        'dashboard.viz.customerComment.title': 'Comment of a <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.customerComment.editor.title': 'Comment of a <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.customerComment.editor.vizTitle': 'Header title',
        'dashboard.viz.customerComment.editor.comment': 'Comment',
        'dashboard.viz.customerComment.editor.feedbacks': 'Verbatims',
        'dashboard.viz.customerComment.editor.customFields': 'Custom fields',
        'dashboard.viz.customerComment.editor.headerFields': 'Header fields',

        'dashboard.viz.standardProfile.title': 'Standard profile of a <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.standardProfile.editor.title': 'Standard profile of a <0>$t(satType.{{satType}}.{{sat}})</0>',
        'dashboard.viz.standardProfile.editor.feedback': 'Verbatim',
        'dashboard.viz.standardProfile.editor.profile': 'Profile',
        'dashboard.viz.standardProfile.editor.profileItem': 'Profile item',
        'dashboard.viz.standardProfile.editor.feedbackTitle': 'Verbatim title',

        'dashboard.viz.tetraClasse.title': 'Asymmetry of contributions',
        'dashboard.viz.tetraClasse.header.topic': "$t(dashboard.misc.topic_plural)",
        'dashboard.viz.tetraClasse.header.x': 'Negative/dissatisfaction',
        'dashboard.viz.tetraClasse.header.y': 'Positive/satisfaction',
        'dashboard.viz.tetraClasse.header.classe': 'Class',
        'dashboard.viz.tetraClasse.header.count': 'conversations',

        'dashboard.viz.topicSatScore.title.NPS.POSITIVE': 'Top 3 topics | Promoters',
        'dashboard.viz.topicSatScore.title.NPS.NEGATIVE': 'Top 3 topics | Detractors',
        'dashboard.viz.topicSatScore.title.CSAT.POSITIVE': 'Top 3 topics | Satisfied customers',
        'dashboard.viz.topicSatScore.title.CSAT.NEGATIVE': 'Top 3 topics | Dissatisfied customers',
        'dashboard.viz.topicSatScore.title.CES.POSITIVE': 'Top 3 topics | Low customer effort',
        'dashboard.viz.topicSatScore.title.CES.NEGATIVE': 'Top 3 topics | High customer effort',
        'dashboard.viz.topicSatScore.editor.verbatims': 'Verbatims',
        'dashboard.viz.topicSatScore.editor.params': 'Parameters',
        'dashboard.viz.topicSatScore.editor.metricRanges': 'Thresholds',
        'dashboard.viz.topicSatScore.editor.exclusions': 'Exclusions',
        'dashboard.viz.topicSatScore.editor.inclusions': 'Inclusions',
        'dashboard.viz.topicSatScore.editor.sort': 'Sort',

        'dashboard.viz.customerJourneySatScore.title': 'Performance by journey',
        'dashboard.viz.customerJourneySatScore.editor.title': 'Performance by journey',
        'dashboard.viz.customerJourneySatScore.editor.positiveThreshold': 'Define positive starting at',

        'dashboard.viz.distribution.title': '$t({{field}}) distribution',
        'dashboard.viz.distribution.editor.title': '$t({{field}}) distribution',
        'dashboard.viz.distribution.editor.size': 'Number of values',
        'dashboard.viz.distribution.editor.display': 'Display',
        'dashboard.viz.distribution.editor.showPercent': 'Show percent',
        'dashboard.viz.distribution.editor.showVolume': 'Show volume',
        'dashboard.viz.distribution.editor.showTrend': 'Show trend',
        'dashboard.viz.distribution.editor.color': 'Color',

        'dashboard.editor.distribution.title': 'Distribution',
        'dashboard.editor.distribution.enabled': 'Enable',
        'dashboard.editor.distribution.showNeutral': 'Show neutral',
        'dashboard.editor.distribution.showPercent': 'Show percent',
        'dashboard.editor.distribution.showVolume': 'Show volume',
        'dashboard.editor.distribution.distributionKind': 'Type',
        'dashboard.editor.distribution.showParticipants': 'Show number of participants',

        'dashboard.misc.point': '<0>$t(misc.signedValue)</0> <1>$t(misc.unit.point)</1>',
        'dashboard.misc.polarity.positive': 'positive',
        'dashboard.misc.polarity.positive_plural': 'positives',
        'dashboard.misc.polarity.negative': 'negative',
        'dashboard.misc.polarity.negative_plural': 'negatives',
        'dashboard.misc.topic': 'topic',
        'dashboard.misc.topic_plural': 'topics',

        'dashboard.title': 'Customer Satisfaction Report',

        'dashboard.viz.mainSatScore.description.short': 'The score here corresponds to the chosen filter (period, score type and selected field). The evolution under the score takes into account the same period, and the "participants" correspond to all the scores of the selected score tab.',
        'dashboard.viz.refSatScore.description.short': 'This score corresponds to the 2nd selected field (the field for the comparison).',
        'dashboard.viz.globalSatScore.description.short': 'The global score, with the only filter being the selected time period.',
        'dashboard.viz.satEvolution.description.short': 'This chart shows the evolution of the selected score.',
        'dashboard.viz.bestTopicsRanking.description.short': 'This table shows the themes with the highest scores and the highest number of positive comments.',
        'dashboard.viz.worstTopicsRanking.description.short': 'This table shows the themes with the lowest scores and the highest number of negative comments.',
        'dashboard.viz.positiveTopicImpact.description.short': 'This table shows themes that, when mentioned in comments, are strongly related to a positive score.',
        'dashboard.viz.negativeTopicImpact.description.short': 'This table shows themes that, when mentioned in comments, are strongly related to a negative score.',
        'dashboard.viz.customerJourneySat.description.short': 'This chart shows the comparison of the parent categories based on their score. The displayed score corresponds to the global score of all customers who left a comment on one of the themes of this category.',
        'dashboard.viz.customersToRecall.description.short': 'This chart shows, for the selected filter, the number of customers who left a comment on one of the risk themes. For more details, go to the "Conversations" section.',
        'dashboard.viz.positiveTopicImpactTopEvolution.description.short': 'The 3 themes whose positive impact has evolved the most.',
        'dashboard.viz.negativeTopicImpactTopEvolution.description.short': 'The 3 themes whose negative impact has evolved the most.',
        'dashboard.viz.topThreeBestTopics.description.short': 'For illustrative purposes, you can choose a comment for each of the TOP 3 "areas doing well" themes.',
        'dashboard.viz.topThreeWorstTopics.description.short': 'For illustrative purposes, you can choose a comment for each of the TOP 3 "areas doing improve" themes.',
        'dashboard.viz.actionList.description.short': 'You can use this chart to write a maximum of 3 short statements to summarize what needs to be shared or highlighted.',
        'dashboard.viz.mainRanking.description.short': 'This chart shows the comparison of items, taking into account their scores, the volume of comments and the sentiment.',
        'dashboard.viz.refRanking.description.short': 'This chart shows the comparison of items of the 2nd field selected above (the field for the comparison).',
        'dashboard.viz.promoterCustomerComment.description.short': 'We can choose a comment and add a title and a brief description to capture the profile of satisfied customers.',
        'dashboard.viz.detractorCustomerComment.description.short': 'We can choose a comment and add a title and a brief description to capture the profile of unsatisfied customers.',
        'dashboard.viz.globalSatEvolution.description.short': 'The global score, with the only filter being the selected time period.',
        'dashboard.viz.mainVolume.description.short': 'This chart shows the volume of comments that correspond to the selected filter.',
        'dashboard.viz.volumeEvolution.description.short': 'This chart shows the evolution of the volume of comments, compared to the evolution of the global volume of comments.',
        'dashboard.viz.actionPlan.description.short': 'This chart breaks down the themes according to their importance on the overall score and their sentiment. On the vertical axis, the term "Importance" refers to the volume of comments for each theme. The "Enhance" category includes themes that strongly impact the overall score in a negative way. The "Capitalize" category includes themes that strongly impact the overall score in a positive way. The "Watch" category includes themes that have a little negative impact on the overall score. The "Generalize" category includes themes that have a positive and weak impact.',
        'dashboard.viz.distributionEaseOfContact.description.short': 'This chart shows the distribution of the satisfaction for the closed-question.',
        'dashboard.viz.distributionDelayContact.description.short': 'This chart shows the distribution of the satisfaction for the closed-question.',
        'dashboard.viz.distributionReception.description.short': 'This chart shows the distribution of the satisfaction for the closed-question.',
        'dashboard.viz.distributionNeeds.description.short': 'This chart shows the distribution of the satisfaction for the closed-question.',
        'dashboard.viz.distributionResponse.description.short': 'This chart shows the distribution of the satisfaction for the closed-question.',
        'dashboard.viz.distributionAdvice.description.short': 'This chart shows the distribution of the satisfaction for the closed-question',

        'dashboard.viz.distributionChart.group.other': 'Others',
        'dashboard.viz.pieChart.tooltip.field': 'Field',
        'dashboard.viz.pieChart.tooltip.value': 'Value',
        'dashboard.viz.pieChart.tooltip.count': 'Count',
        'dashboard.viz.pieChart.tooltip.percentage': '%',
        'dashboard.viz.distributionChart.tooltip.count': 'Count',


    'dashboard.viz.table.volume': 'Volume',
    'dashboard.viz.table.opinions': 'Opinions',
    'dashboard.viz.table.distribution': 'Distribution',
    'dashboard.viz.table.intent': 'Intent',
    'dashboard.viz.table.trend': 'Trend',
    'dashboard.viz.table.satScore': 'Score',
    'dashboard.viz.table.tonalities': 'Tonalities',

        'rangeEditor.min': 'Min',
        'rangeEditor.max': 'Max',
        'rangeEditor.error.minMax': 'Value must be between {{min}} and {{max}}',
        'rangeEditor.error.min': 'Value must be greater or equal to {{min}}',
        'rangeEditor.error.max': 'Value must be lower or equal to {{max}}',

        'action.add': 'Add',
        'action.export': 'Export',
        'action.cancel': 'Cancel',
        'action.cancelAll': 'Cancel all',
        'action.validate': 'Validate',
        'action.apply': 'Apply',
        'action.delete': 'Delete',
        'action.deleteAll': 'Delete all',
        'action.showMore': 'Show more ...',
        'action.showLess': 'Show less ...',
        'action.search': 'Search',
        'action.all': 'All',
        'action.selectAll': 'Select all',
        'action.clearSelection': 'Clear selection',
        'action.invertSelection': 'Invert selection',
        'action.close': 'Close',
        'action.expandAll': 'Expand all nodes',
        'action.collapseAll': 'Collapse all nodes',
        'action.browse': 'Browse',
        'action.refresh': 'Refresh',
        'action.yes': 'Yes',
        'action.no': 'No',
        'action.save': 'Save',
        'action.restore': 'Restore',
        'action.replay': 'Replay',
        'action.replayAll': 'Replay all',

        'confirm.confirmInputLabel': 'To confirm this action, you must enter "{{confirmText}}"',

        'form.required': 'required',

        'input.password.placeholder': 'Enter password...',
        'input.password.show': 'Show password',
        'input.password.hide': 'Hide password',

        'dashboard.viz.noData': 'No information detected in conversations',
        'dashboard.viz.noData.light': 'No results found',
        'dashboard.viz.unknownValue': 'Unknown value',

        'export.highlight.title': 'Export highlights',
        'export.highlight.param.max.label': 'Maximum',
        'export.highlight.param.max.helper': 'Maximum number of feedbacks to export...',
        'export.highlight.param.categoryTree.label': 'Category tree',
        'export.highlight.param.categoryTree.helper': 'Category tree to highlight...',
        'export.highlight.param.tonality.label': 'Tonality',
        'export.highlight.param.tonality.helper': 'Tonality of the offsets to be highlighted...',
        'export.highlight.param.lang.label': 'Language',
        'export.highlight.param.lang.helper': 'Language in which the category tree will be translated',

        'period.month': 'From {{from}} to {{to}}',
        'period.day': 'From {{from}} to {{to}}',
        'period.week.this': 'This week',
        'period.week.last': 'Last week',
        'period.week': 'Week {{week}}',
        'period.quarter.1': 'First quarter',
        'period.quarter.2': 'Second quarter',
        'period.quarter.3': 'Third quarter',
        'period.quarter.4': 'Fourth quarter',
        'period.kind.WEEK': 'Week',
        'period.kind.MONTH': 'Month',
        'period.kind.QUARTER': 'Quarter',
        'period.kind.12MONTHS': 'Last 12 months',

        'satType': 'Satisfaction kind',

        'satType.NPS': 'Net Promoter Score',
        'satType.NPS.short': 'NPS',
        'satType.NPS.POSITIVE': 'promoter',
        'satType.NPS.POSITIVE_plural': 'promoters',
        'satType.NPS.NEGATIVE': 'detractor',
        'satType.NPS.NEGATIVE_plural': 'detractors',
        'satType.NPS.NEUTRAL': 'passive',
        'satType.NPS.NEUTRAL_plural': 'passives',

        'satType.CSAT': 'Customer Satisfaction Score',
        'satType.CSAT.short': 'CSAT',
        'satType.CSAT.POSITIVE': 'satisfied customer',
        'satType.CSAT.POSITIVE_plural': 'satisfied customers',
        'satType.CSAT.NEGATIVE': 'dissatisfied customer',
        'satType.CSAT.NEGATIVE_plural': 'dissatisfied customers',

        'satType.CES': 'Customer Effort Score',
        'satType.CES.short': 'CES',
        'satType.CES.POSITIVE': 'low customer effort',
        'satType.CES.POSITIVE_plural': 'low customer efforts',
        'satType.CES.NEGATIVE': 'high customer effort',
        'satType.CES.NEGATIVE_plural': 'high customer efforts',

        'metric.SAT_SCORE': 'Satisfaction score',
        'metric.VOLUME': 'Volume',
        'metric.VOLUME_TREND': 'Volume trend',
        'metric.PERCENTAGE': 'Perc.',
        'metric.TREND': 'Sat Trend',
        'metric.OPINIONS': '$t(common.Tonality.{{tonality}}_plural) $t(misc.opinion_plural)',
        'metric.OPINIONS_FEEDBACK': '$t(common.Tonality.{{tonality}}_plural) $t(misc.feedback_plural)',
        'metric.POSITIVE_OPINIONS': '$t(common.Tonality.POSITIVE_plural) $t(misc.opinion_plural)',
        'metric.NEGATIVE_OPINIONS': '$t(common.Tonality.NEGATIVE_plural) $t(misc.opinion_plural)',

        'topicStatDetailViewMode.FEEDBACKS': '$t(misc.feedback_plural)',
        'topicStatDetailViewMode.SEQUENCES': 'Sequences',

        'sortOrder': 'Order',
        'sortOrder.ASC': 'Ascending',
        'sortOrder.DESC': 'Descending',

        'tetraClasse.CLE': 'Key',
        'tetraClasse.PLUS': 'Plus',
        'tetraClasse.SECONDAIRE': 'Secondary',
        'tetraClasse.BASIQUE': 'Basic',

        'lang.fr': 'French',
        'lang.en': 'English',

        'error.runtime': 'An error occurred',
        'error.http': 'An error occurred during communication with the server',
        'error.http.authorization': 'Operation not authorized',
        'error.http.dashboardExport.initiateExport': 'Could not export report',
        'error.http.feedback.getFeedback': 'Could not get feedback identified with ID : {{args.0}}',
        'error.http.configuration.getFieldsConfiguration': 'Could not load configuration',
        'error.http.storage.uploadStorageFile.415': 'Unsupported file kind',
        'error.application.load': 'An error occurred while loading vecko',
        'error.404.title': 'PAGE NOT FOUND',
        'error.404.description': 'The page you are looking for might have been removed, or you are not authorized to reach it.',
        'error.404.backToHome': 'Go back to home page',

        'feedback.debug.operations': 'Operations log',
        'feedback.debug.source': 'Source',
        'feedback.debug.source.api': 'Api',
        'feedback.debug.source.audio': 'Audio',
        'feedback.debug.source.transcribed': 'Transcribed',
        'feedback.debug.source.original': 'Original',
        'feedback.debug.source.raw': 'Raw',
        'feedback.debug.source.analyzed': 'Analyzed',
        'feedback.debug.source.indexed': 'Indexed',
        'feedback.debug.source.summary': 'Summary',
        'feedback.debug.categorizationRules': 'Categorization rules',
        'feedback.debug.categorizationDetails': 'Categorization details',

        'storageKind.FEEDBACK_MIRRORED': 'Recent files',
        'storageKind.FEEDBACK_DEMO': 'Demo files',

        'administration': 'Administration',
        'administration.system': 'System',
        'administration.system.cache': 'Caches',
        'administration.system.cache.local': 'In-memory caches',
        'administration.system.cache.local.description': 'Memory caches are caches with short lifespans (a few minutes to a few days). The data stored in this type of cache is frequently read data but the loss of which has no consequences.',
        'administration.system.cache.local.description-example': 'Among the data hidden in the memory caches are, for example, urls of the lingwares and their versions.',
        'administration.system.cache.persistent': 'Persistent caches',
        'administration.system.cache.persistent.description': 'Persistent caches are caches whose data is stored in a database. Application stop has no effect on the type of cache. Data generally stored in this kind of cache is expensive to build or access.',
        'administration.system.cache.persistent.description-example': 'Among the cached data in the persistent caches are obfuscation results, linguistic analysis results, language detections and extraction, significant parts of input data or audio file transcription',
        'administration.system.cache.local.invalidate': 'Invalidate',
        'administration.system.cache.persistent.invalidate': 'Invalidate for current tenant',
        'administration.system.cache.persistent.invalidate.multiTenant': 'Invalidate for all tenants',
        'administration.system.cache.invalidation.success': 'Invalidation done successfully.',
        'administration.system.corpus': 'Corpus',
        'administration.system.corpus.feedbackStats': 'Feedback counts',
        'administration.system.corpus.feedbackStats.empty': 'There is not feedback',
        'administration.system.corpus.feedbackStats.date': 'Date',
        'administration.system.corpus.feedbackStats.total': 'Total',
        'administration.system.corpus.feedbackStats.categorized': 'Categorized',
        'administration.system.corpus.feedbackStats.notEmpty': 'Not empty',
        'administration.system.corpus.feedbackStats.coverage': 'Coverage',
        'administration.system.corpus.tenantInfo': 'Overview',
        'administration.system.corpus.tenantInfo.tenant': 'Tenant',
        'administration.system.corpus.tenantInfo.tenant.name': 'Name',
        'administration.system.corpus.tenantInfo.tenant.id': 'Id',
        'administration.system.corpus.tenantInfo.tenant.admin': 'admin',
        'administration.system.corpus.tenantInfo.cassandra': 'Cassandra',
        'administration.system.corpus.tenantInfo.cassandra.keyspace': 'Keyspace',
        'administration.system.corpus.tenantInfo.cassandra.datacenter': 'Datacenter',
        'administration.system.corpus.tenantInfo.idp': 'IDP',
        'administration.system.corpus.tenantInfo.idp.realm': 'Realm',
        'administration.system.corpus.tenantInfo.idp.clientId': 'Client id',
        'administration.system.corpus.tenantInfo.idp.link': 'admin',
        'administration.system.purge': 'Data purge',
        'administration.system.purge.launch': 'Launch purge',
        'administration.system.purge.by_date': 'Purge feedback data by date',
        'administration.system.purge.by_date.button-label': 'Purge by date',
        'administration.system.purge.by_date.confirmation-message': 'Are you sure you want to purge feedback data by date?',
        'administration.system.purge.by_date.request.success': 'Purge by date request sent successfully.',
        'administration.system.purge.by_duration': 'Purge feedback data by duration',
        'administration.system.purge.by_duration.button-label': 'Purge by duration',
        'administration.system.purge.by_duration.request.success': 'Purge by duration request sent successfully.',
        'administration.system.purge.by_duration.confirmation-message': 'Are you sure you want to purge feedback data by duration?',
        'administration.system.purge.by_duration.nb_days': 'days',
        'administration.system.purge.by_duration.nb_months': 'months',
        'administration.system.purge.by_duration.nb_years': 'years',
        'administration.system.purge.by_configuration': 'Purge feedback data by configuration',
        'administration.system.purge.by_configuration.button-label': 'Purge by configuration',
        'administration.system.purge.by_configuration.actual-configuration': 'Actual configuration for tenant',
        'administration.system.purge.by_configuration.request.success': 'Purge by configuration request sent successfully.',
        'administration.system.purge.by_configuration.confirmation-message': 'Are you sure you want to purge feedback data by configuration?',
        'administration.system.purge.all': 'Purge all feedback data',
        'administration.system.purge.all.button-label': 'Purge all data',
        'administration.system.purge.all.request.success': 'Purge all data request sent successfully.',
        'administration.system.purge.all.confirmation-message': 'Are you sure you want to purge all feedback data?',
        'administration.system.purge.errors': 'Purge feedback errors',
        'administration.system.purge.errors.button-label': 'Purge',
        'administration.system.purge.errors.confirmation-message': 'Are you sure you want to purge feedback errors?',
        'administration.system.purge.errors.request.success': 'Purge feedback errors request sent successfully.',
        'administration.sourcing': 'Sourcing',
        'administration.sourcing.storage.demo': '$t(storageKind.FEEDBACK_DEMO)',
        'administration.sourcing.storage.mirrored': '$t(storageKind.FEEDBACK_MIRRORED)',
        'administration.sourcing.storage.sourcePath': 'Source this file',
        'administration.sourcing.storage.sourceSelection': 'Source selected',
        'administration.sourcing.storage.source.result.none': 'No file sent to sourcing',
        'administration.sourcing.storage.source.result.one': 'File "{{source}}" sent to sourcing : {{count}} feedbacks',
        'administration.sourcing.storage.source.result.many': '{{count}} files sent to sourcing : {{total}} feedbacks',
        'administration.sourcing.storage.uploadFile': 'Add a file',
        'administration.sourcing.storage.uploadFile.progress': 'Upload in progress',
        'administration.sourcing.storage.uploadFile.success': 'File "{{file}}" uploaded successfully',
        'administration.sourcing.storage.deletePath': 'Delete',
        'administration.sourcing.storage.deleteSelection': 'Delete selected',
        'administration.sourcing.storage.deletePaths.confirm': 'Are you sure you want to delete? This cannot be undone!',
        'administration.sourcing.storage.delete.failure': 'One item could not be deleted',
        'administration.sourcing.storage.delete.failure_plural': '{{count}} items could not be deleted',
        'administration.sourcing.storage.downloadFile': 'Download',
        'administration.sourcing.storage.downloadSelection': 'Download selected',
        'administration.sourcing.storage.noFiles': 'No files',
        'administration.sourcing.csv.options.label': 'Options',
        'administration.sourcing.csv.options.charset.label': 'File encoding',
        'administration.sourcing.csv.options.delimiterChar.label': 'Separator',
        'administration.sourcing.csv.options.quoteChar.label': 'Quote',

        'administration.sourcing.local': 'Local files',
        'administration.sourcing.local.xlsx': 'XLSX file',
        'administration.sourcing.local.csv': 'CSV file',
        'administration.sourcing.local.json': 'JSON file',
        'administration.sourcing.local.audio': 'Audio file',
        'administration.sourcing.local.audio.audioFile.helper': 'Choose the audio file. Supported formats: {{formats}}',
        'administration.sourcing.local.audio.audioFile.label': 'Audio file',
        'administration.sourcing.local.audio.audioMetadata.helper': 'Describe the Audio file',
        'administration.sourcing.local.audio.audioMetadata.label': 'Audio file settings',
        'administration.sourcing.local.audio.audioMetadata.language': 'Language',
        'administration.sourcing.local.audio.audioMetadata.audioFormat': 'Audio encoding',
        'administration.sourcing.local.audio.audioMetadata.sampleRate': 'Sample rate (Hz)',
        'administration.sourcing.local.audio.audioMetadata.callDirection': 'Call direction',
        'administration.sourcing.local.audio.audioMetadata.channels': 'Channels',
        'administration.sourcing.local.audio.audioMetadata.channels.mono': 'Mono',
        'administration.sourcing.local.audio.audioMetadata.channels.stereo': 'Stereo',
        'administration.sourcing.local.audio.metadata.helper': 'Choose the metadata JSON file that will be added to the feedback',
        'administration.sourcing.local.audio.metadata.label': 'Metadata file',
        'administration.sourcing.local.file.label': 'File',
        'administration.sourcing.local.file.text': 'Choose file ...',
        'administration.sourcing.local.source': 'Source',
        'administration.sourcing.local.source.success': 'File successfully sent to sourcing',

        'administration.sourcing.progress': 'Progress',
        'administration.sourcing.progress.none': 'No sourcing',
        'administration.sourcing.progress.count.tenants': 'Tenants',
        'administration.sourcing.progress.count.sourcing': 'Sourcings',
        'administration.sourcing.progress.count.emptySourcing': 'Empty sourcings',
        'administration.sourcing.progress.count.inputFeedbacks': 'Input feedbacks',
        'administration.sourcing.progress.count.indexedFeedbacks': 'Indexed feedbacks',
        'administration.sourcing.progress.dangerousActionWarning': 'Attention, this action is irreversible',
        'administration.sourcing.progress.delete.success': 'Sourcing has been removed',
        'administration.sourcing.progress.delete.confirm': 'Are you sure you want to remove this sourcing.',
        'administration.sourcing.progress.delete.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning): All data will be deleted!',
        'administration.sourcing.progress.deleteAll.success': 'All sourcings has been removed',
        'administration.sourcing.progress.deleteAll.confirm.all': 'Are you sure you want to delete all sourcings for all tenants? This action will impact {{tenantsCount}} tenants. One sourcing will be deleted.',
        'administration.sourcing.progress.deleteAll.confirm.all_plural': 'Are you sure you want to delete all sourcings for all tenants? This action will impact {{tenantsCount}} tenants. {{count}} sourcings will be deleted.',
        'administration.sourcing.progress.deleteAll.confirm.one': 'Are you sure you want to delete all the sourcings of the tenant {{tenant}}? One sourcing will be deleted.',
        'administration.sourcing.progress.deleteAll.confirm.one_plural': 'Are you sure you want to delete all the sourcings of the tenant {{tenant}}? {{count}} sourcings will be deleted.',
        'administration.sourcing.progress.deleteAll.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning): All data will be deleted!',
        'administration.sourcing.progress.cancel.success': 'Sourcing has been canceled',
        'administration.sourcing.progress.cancelAll.success': 'All sourcings has been canceled',
        'administration.sourcing.progress.cancelAll.confirm.all': 'Are you sure you want to cancel all sourcings for all tenants? This action will impact {{tenantsCount}} tenants. One sourcing will be canceled.',
        'administration.sourcing.progress.cancelAll.confirm.all_plural': 'Are you sure you want to cancel all sourcings for all tenants? This action will impact {{tenantsCount}} tenants. {{count}} sourcings will be canceled.',
        'administration.sourcing.progress.cancelAll.confirm.one': 'Are you sure you want to cancel all the sourcings of the tenant {{tenant}}? One sourcing will be canceled.',
        'administration.sourcing.progress.cancelAll.confirm.one_plural': 'Are you sure you want to cancel all the sourcings of the tenant {{tenant}}? {{count}} sourcings will be canceled.',
        'administration.sourcing.progress.reindex': 'Reindex all',
        'administration.sourcing.progress.reindex.confirm.one': 'Are you sure you want to reindex data for tenant {{tenant}}?',
        'administration.sourcing.progress.reindex.confirm.all': 'Are you sure you want to reindex data for all tenants? This action will impact {{tenantsCount}} tenants.',
        'administration.sourcing.progress.reindex.confirm.warn': '$t(administration.sourcing.progress.dangerousActionWarning): All data will be reindex!',
        'administration.sourcing.progress.replay': 'Replay',
        'administration.sourcing.progress.replay.success': 'Sourcing has been replayed',
        'administration.sourcing.progress.replayAll': 'Replay all',
        'administration.sourcing.progress.replayAll.success': 'All sourcings has been replayed',
        'administration.sourcing.progress.replayAll.confirm.all': 'Are you sure you want to replay all sourcings for all tenants? This action will impact {{tenantsCount}} tenants. One sourcing will be replayed.',
        'administration.sourcing.progress.replayAll.confirm.all_plural': 'Are you sure you want to replay all sourcings for all tenants? This action will impact {{tenantsCount}} tenants. {{count}} sourcings will be replayed.',
        'administration.sourcing.progress.replayAll.confirm.one': 'Are you sure you want to replay all the sourcings of the tenant {{tenant}}? One sourcing will be replayed.',
        'administration.sourcing.progress.replayAll.confirm.one_plural': 'Are you sure you want to replay all the sourcings of the tenant {{tenant}}? {{count}} sourcings will be replayed.',
        'administration.sourcing.progress.cleanup': 'Cleanup',
        'administration.sourcing.progress.cleanup.success': 'All sourcing without feedback have been deleted',
        'administration.sourcing.progress.cleanup.confirm.all': 'Are you sure you want to delete all sourcings without feedback for all tenants? This action will impact {{tenantsCount}} tenants. One sourcing will be deleted.',
        'administration.sourcing.progress.cleanup.confirm.all_plural': 'Are you sure you want to delete all sourcings without feedback for all tenants? This action will impact {{tenantsCount}} tenants. {{count}} sourcings will be deleted.',
        'administration.sourcing.progress.cleanup.confirm.one': 'Are you sure you want to delete all sourcings without feedback for tenant {{tenant}} ? One sourcing will be deleted.',
        'administration.sourcing.progress.cleanup.confirm.one_plural': 'Are you sure you want to delete all sourcings without feedback for tenant {{tenant}} ? {{count}} sourcings will be deleted.',
        'administration.sourcing.progress.cleanup.tooltip': 'Delete the sourcing without feedback',
        'administration.sourcing.progress.cleanup.tooltip_plural': 'Delete the {{count}} sourcings without feedback',
        'administration.sourcing.progress.details': 'See details',
        'administration.sourcing.progress.table.date': 'Date',
        'administration.sourcing.progress.table.tenant': 'Tenant',
        'administration.sourcing.progress.table.input': 'Input feedbacks',
        'administration.sourcing.progress.table.sourcing': 'Sourcing',
        'administration.sourcing.progress.table.processing': 'Processing',
        'administration.sourcing.progress.table.indexing': 'Indexing',
        'administration.sourcing.progress.table.indexed': 'Indexed feedbacks',
        'administration.sourcing.progress.table.errors': 'Errors',
        'administration.sourcing.progress.table.sourcingParsingDiscarded': 'Discarded at source parsing feedbacks',
        'administration.sourcing.progress.table.sourcingRuleDiscarded': 'Discarded at parsing rule execution feedbacks',
        'administration.sourcing.progress.details.substream': 'ID',
        'administration.sourcing.progress.details.input.api': 'Feedbacks coming through the sourcing API',
        'administration.sourcing.progress.details.input.di': 'Feedbacks coming from Vecko-Data-Integration',
        'administration.sourcing.progress.details.input.audio': 'Audio input feedbacks',
        'administration.sourcing.progress.details.sourcing.transcribed': 'Audio transcription',
        'administration.sourcing.progress.details.sourcing.raw': 'Sourcing',
        'administration.sourcing.progress.details.pushed': 'Pushed feedbacks',
        'administration.sourcing.progress.details.retries': ' retries',
        'administration.sourcing.progress.details.acknowledged': ' acknowledged',
        'administration.sourcing.progress.empty': 'None',
        'administration.sourcing.logs': 'Logs',
        'administration.sourcing.logs.none': 'No data',
        'administration.sourcing.logs.details': 'See details',
        'administration.sourcing.logs.details.stacktrace': 'StackTrace',
        'administration.sourcing.logs.table.date': 'Date',
        'administration.sourcing.logs.table.group': 'Group',
        'administration.sourcing.logs.table.tenant': 'Tenant',
        'administration.sourcing.logs.table.stream': 'Stream',
        'administration.sourcing.logs.table.substream': 'Substream',
        'administration.sourcing.logs.table.id': 'Id',
        'administration.sourcing.logs.table.message': 'Message',
        'administration.sourcing.logs.table.error': 'Error',
        'administration.sourcing.debug': 'Debug',
        'administration.sourcing.debug.tenant': 'Tenant:',
        'administration.sourcing.debug.substream': 'Substream: :',
        'administration.sourcing.debug.veckoId': 'Internal ID:',
        'administration.sourcing.errors': 'Errors',
        'administration.sourcing.errors.none': 'No data',
        'administration.sourcing.errors.details': 'See details',
        'administration.sourcing.errors.debug': 'Open debug view',
        'administration.sourcing.errors.relaunch': 'Relaunch',
        'administration.sourcing.errors.details.stacktrace': 'StackTrace',
        'administration.sourcing.errors.table.system.date': 'Date',
        'administration.sourcing.errors.table.system.topic': 'Topic',
        'administration.sourcing.errors.table.system.tenantName': 'Tenant',
        'administration.sourcing.errors.table.system.stream': 'Stream',
        'administration.sourcing.errors.table.system.substream': 'Substream',
        'administration.sourcing.errors.table.system.id': 'Id',
        'administration.sourcing.errors.table.message': 'Message',
        'administration.sourcing.errors.table.error': 'Error',
        'administration.sourcing.feedback.relaunch.success': 'Process relaunched successfully',
        'administration.configuration': 'Configurations',
        'administration.configuration.git': 'Git',
        'administration.configuration.git.branch': 'Branch',
        'administration.configuration.git.push.success': 'Configuration pushed successfully',
        'administration.configuration.git.error': 'An error occurred: {{error}}',
        'administration.configuration.validate': 'Validate',
        'administration.configuration.push': 'Push',
        'administration.configuration.validation.hint.level': 'Level',
        'administration.configuration.validation.hint.message': 'Message',
        'administration.configuration.validation.valid': 'The configuration is valid',
        'administration.configuration.validation.invalid': 'The configuration is not valid',
        'administration.configuration.edit': 'Edit',
        'administration.configuration.edit.save.success': 'The configuration has been saved successfully',
        'administration.export': 'Exports',
        'administration.export.export': 'Exports',
        'administration.export.fields': 'Fields',
        'administration.export.content': 'Content',

        'administration.export.output.xlsx': 'XLSX',
        'administration.export.output.csv': 'CSV',
        'administration.export.output.json': 'JSON',
        'administration.export.feedbacks.dataType.indexed': 'Indexed',
        'administration.export.feedbacks.dataType.analyzed': 'Analyzed',
        'administration.export.feedbacks': 'Feedbacks',
        'administration.export.feedbacks.dataType': 'Feedbacks datasource',

        'administration.export.feedbacks.filterType': 'Filter',
        'administration.export.feedbacks.filterType.TAGS_BUT_NO_TOPIC': 'Feedbacks with tag but without topic',
        'administration.export.feedbacks.filterType.NO_TOPIC': 'Feedbacks without topic',
        'administration.export.feedbacks.filterType.NO_TAG': 'Feedbacks without tag',
        'administration.export.feedbacks.filterType.WITH_TAG': 'Feedbacks with tag',
        'administration.export.feedbacks.filterType.WITH_TOPIC': 'Feedbacks with topic',
        'administration.export.feedbacks.filterType.ALL': 'All the feedbacks',

        'administration.export.output': 'Output format',
        'administration.export.output.xlsx.description': '',
        'administration.export.output.json.description': '(Encoding: \'UTF-8\')',
        'administration.export.output.csv.description': '(Encoding: \'ISO_8859_1\'. Separator: \';\'. Quotes: \'"\', Line separator:\'\\n\\r\')',

        'administration.export.feedbacks.description.text': 'Exported data.',
        'administration.export.feedbacks.description.fields': 'All fields defined as exportable in the fields configuration (FIELDS).',
        'administration.export.feedbacks.indexed.description.content': 'The value of each field is the one found in the elasticsearch index.',
        'administration.export.feedbacks.analyzed.description.content': 'The value of each field is the one found in the cassandra table \'feedback_analyzed\'.',

        'administration.export.feedbacks.maxSize': 'Maximum number of feedbacks',


        'administration.export.highlight': 'Highlight',
        'administration.export.highlight.description.text': 'Exports the detail of the verbatim analysis.',
        'administration.export.highlight.description.fields': 'The output file is made of several html pages (one per category)',
        'administration.export.highlight.description.content': 'Each html page contains the sentences on which the category has been found',
        'administration.export.highlight.description.output': 'Format: \'ZIP\'.',

        'administration.di': 'Data integration',
        'administration.di.di.configuration': 'Configuration',
        'administration.di.configuration.description': 'This configuration defines the user that will be used when vecko-di calls vecko-api Rest APIs. This user must have the role \'c-vecko-di\'.',
        'administration.di.configuration.create': 'You can create the user with the following link:',
        'administration.di.configuration.create.link': 'Create the user',
        'administration.di.configuration.list': 'You can list the users using the following link:',
        'administration.di.configuration.list.link': 'List users',
        'administration.di.configuration.username': 'Username',
        'administration.di.configuration.password': 'Password',
        'administration.di.configuration.save.success': 'THe configuration has been saved successfully.',
        'administration.di.di.apis': 'Apis',
        'administration.di.apis.add.dialog.title': 'New api configuration',
        'administration.di.apis.add.dialog.kind': 'Kind',
        'administration.di.api.active': 'Active',
        'administration.di.api.duration': 'Number of days to import',
        'administration.di.api.interval.initial': 'Size of the intervals used for the initial import',
        'administration.di.api.interval.stream': 'Size of the intervals used for the streaming',
        'administration.di.api.interval.delay': 'Waiting delay before intervals are processed',
        'administration.di.api.save.success': 'The configuration has been saved successfully.',
        'administration.di.api.delete.success': 'The configuration has been deleted successfully.',
        'administration.di.api.intervals': 'Intervals',
        'administration.di.api.intervals.none': 'No interval',
        'administration.di.api.intervals.status': 'Status',
        'administration.di.api.intervals.interval': 'Interval',
        'administration.di.intervals.delete.success': 'All intervals have been deleted successfully.',

        'tenantSelector.current': 'Current tenant ({{tenant}})',
        'tenantSelector.all': 'All tenants',


        'feedbacksSelector.selected': 'Selected verbatims',
        'feedbacksSelector.all': 'Available verbatims',
        'feedbacksSelector.limitReached': 'Limit number of verbatims reached.',

        'administration.sourcing.progress.reindexStreams': 'Reindex streams',
        'administration.sourcing.progress.reindexStreams.confirm.all': 'Are you sure you want to reindex data for tenant {{tenant}}?',
        'administration.sourcing.progress.reindexStreams.confirm.one': 'Are you sure you want to reindex data for tenant {{tenant}}?'
    }
};
